import React from 'react';

import { textToInnerHTML } from '@onesy/utils';
import { classNames, style } from '@onesy/style-react';
import { List, ListItem, Menu, Type, useSubscription } from '@onesy/ui-react';

import IconMaterialWallet from '@onesy/icons-material-rounded-react/IconMaterialWalletW100';
import IconMaterialOutput from '@onesy/icons-material-rounded-react/IconMaterialOutputW100';

import { Avatar, Divider } from 'ui';
import { AuthService } from 'services';
import { getSettings } from 'utils';
import { ISignedIn } from 'types';

const useStyle = style(theme => ({
  root: {

  },

  avatar: {
    '&.onesy-Avatar-root.onesy-Button-root': {
      cursor: 'pointer',
      userSelect: 'none'
    }
  },

  avatarOpen: {
    outline: `1px solid ${theme.palette.text.default.secondary}`
  },

  menu: {
    maxHeight: '84vh',
    overflow: 'hidden auto',

    '&.onesy-Line-root': {
      width: '100vw',
      maxWidth: 270,
      borderRadius: theme.methods.shape.radius.value(1),
      boxShadow: `0px 4px 32px 0px rgba(${theme.palette.light ? '0, 0, 0' : '255, 255, 255'}, 0.04)`,
      background: theme.palette.light ? theme.palette.color.neutral[100] : theme.palette.color.neutral[5]
    },

    '& .onesy-ListItem-middle .onesy-Type-root': {
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },

    '& .onesy-ListItem-wrapper:not(.onesy-ListSubheader-root)': {
      background: 'none'
    }
  },

  menuSubheader: {
    '&.onesy-ListSubheader-root': {
      background: 'inherit',

      '& .onesy-Type-root': {
        fontWeight: 200
      }
    }
  },

  itemMain: {
    '& + .onesy-Divider-root': {
      marginTop: 0
    }
  },

  arrowDown: {
    transition: theme.methods.transitions.make('transform'),

    '&.open': {
      transform: 'rotate(-180deg)'
    }
  },

  expand: {
    width: '100%'
  },

  addColor: {
    color: theme.palette.light ? 'hsl(198.67deg 97.57% 54%)' : 'hsl(198.67deg 97.57% 84%)'
  },

  divider: {
    '&.onesy-Divider-root': {
      opacity: theme.palette.light ? 0.07 : undefined
    }
  }
}), { name: 'onesy-UserMenu' });

const UserMenu = React.forwardRef(() => {
  const { classes } = useStyle();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [open, setOpen] = React.useState(false);

  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const onSignOut = React.useCallback(async () => {
    await AuthService.signOut();
  }, []);

  const user = signedIn?.customer;

  let initials = '';

  const name = textToInnerHTML(getSettings(signedIn, 'name') || '');

  if (user) {
    const [firstName, lastName] = name;

    initials += firstName.charAt(0);

    initials += ((lastName ? lastName.charAt(0) : firstName.charAt(1)) || '');
  }

  initials = initials?.toLowerCase();

  const customer = signedIn?.customer;

  const balance = customer?.balance;

  const balanceTotal = balance?.total || 0;

  const currency = signedIn?.organization?.settings?.currency || 'EUR';

  const IconProps: any = {
    size: 'regular'
  };

  const TypeProps: any = {
    version: 'b2'
  };

  const ListItemProps: any = {
    color: 'primary',
    size: 'small'
  };

  const dividerProps: any = {
    size: 'small',
    Component: 'li'
  };

  const menu = (
    <List
      className={classes.menu}
    >
      <ListItem
        color='primary'

        start={(
          <Avatar
            color='primary'

            media={getSettings(signedIn, 'settings.images.profile')}
          >
            {initials}
          </Avatar>
        )}

        primary={(
          <Type
            version='b1'

            dangerouslySetInnerHTML={{
              __html: name
            }}
          />
        )}

        secondary={(
          <Type
            version='b2'

            priority='secondary'
          >
            {user?.email}
          </Type>
        )}

        size='small'

        className={classes.itemMain}
      />

      <Divider
        {...dividerProps}
      />

      <ListItem
        color={balanceTotal >= 0 ? 'inherit' : 'error'}

        start={<IconMaterialWallet />}

        primary={(
          <Type
            version='b2'
          >
            {balanceTotal} {currency}
          </Type>
        )}

        secondary={(
          <Type
            version='b2'

            weight={100}
          >
            Balance
          </Type>
        )}

        {...ListItemProps}
      />

      <Divider
        {...dividerProps}
      />

      <ListItem
        start={(
          <IconMaterialOutput
            {...IconProps}
          />
        )}

        primary={(
          <Type
            {...TypeProps}
          >
            Sign out
          </Type>
        )}

        onClick={onSignOut}

        button

        {...ListItemProps}
      />
    </List>
  );

  return (
    <Menu
      open={open}

      onOpen={onOpen}

      onClose={onClose}

      name={menu}

      position='bottom'

      alignment='center'

      AppendProps={{
        offset: [0, 8],
        switch: false
      }}
    >
      <Avatar
        media={getSettings(signedIn, 'settings.images.profile')}

        className={classNames([
          classes.avatar,
          open && classes.avatarOpen
        ])}
      >
        {initials}
      </Avatar>
    </Menu>
  );
});

export default UserMenu;
