import React from 'react';

import { CalendarAvailability } from '@onesy/ui-react';
import { ICalendarAvailability } from '@onesy/ui-react/CalendarAvailability/CalendarAvailability';

const Element: React.FC<ICalendarAvailability> = props => {

  return (
    <CalendarAvailability
      IconProps={{
        size: 'large'
      }}

      {...props}
    />
  );
};

export default Element;
