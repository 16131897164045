import React from 'react';

import { DatePicker } from '@onesy/ui-react'
import { IDatePicker } from '@onesy/ui-react/DatePicker/DatePicker';

const Element: React.FC<IDatePicker> = props => {

  return (
    <DatePicker
      size='small'

      IconProps={{
        size: 'regular'
      }}

      {...props}
    />
  );
};

export default Element;
