import React from 'react';

import { IconButton, Line, Tooltip, useForm, useLocation, useSnackbars, useSubscription } from '@onesy/ui-react';
import { IBaseElement } from '@onesy/ui-react/types';
import { classNames, style } from '@onesy/style-react';
import { copy, debounce, getQueryParams, hash, is } from '@onesy/utils';
import { IResponse } from '@onesy/sdk/other';

import IconMaterialClose from '@onesy/icons-material-rounded-react/IconMaterialCloseW100';

import { AutoCompleteObjects, Date, Select, TextField } from 'ui';
import { UserService } from 'services';
import { getErrorMessage } from 'utils';
import { IQuerySubscription } from 'types';

const useStyle = style(theme => ({
  root: {
    width: 'calc(100vw - 32px)'
  },

  ...theme.classes(theme)
}), { name: 'onesy-Search' });

export interface ISearchItem {
  name: string;

  type?: 'text' | 'select' | 'date' | 'objects';

  property: string;

  options?: any[];

  default?: any;

  multiple?: boolean;

  props?: any;
}

export interface ISearch extends IBaseElement {
  service?: any;

  search?: ISearchItem[];

  queryDefault?: any;

  queryObjectsName?: string;

  parent?: any;

  timeout?: number;

  onSearch?: (value: any) => any;

  onQueryAfter?: (result: IResponse) => any;
}

const Element: React.FC<ISearch> = React.forwardRef((props, ref) => {
  const {
    service,

    search,

    queryDefault,

    queryObjectsName = 'queryObjects',

    parent,

    timeout = 440,

    onSearch: onSearchProps,

    onQueryAfter,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const location = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const queryObjects = service && useSubscription<IQuerySubscription>(service[queryObjectsName]);

  const [loaded, setLoaded] = React.useState(false);

  const queryParams: any = React.useMemo(() => {
    return getQueryParams();
  }, [location]);

  const queryInitial = React.useMemo(() => {
    const value = {};

    search?.forEach(item => {
      if (item.default !== undefined) value[item.property] = item.default;
    });

    return value;
  }, []);

  const form = useForm({
    values: {
      input: {
        name: 'Input',
        value: {
          ...queryDefault,

          ...queryInitial,

          ...queryParams
        }
      }
    },
    valueDefault: {
      input: {
        ...queryDefault,

        ...queryInitial,
      }
    }
  });

  const refs = {
    form: React.useRef(form),
    parent: React.useRef(parent),
    search: React.useRef(search),
    queryDefault: React.useRef(queryDefault),
    queryObjectsName: React.useRef(queryObjectsName),
    onQueryAfter: React.useRef(onQueryAfter),
    onSearchProps: React.useRef(onSearchProps),
    queryInitial: React.useRef(queryInitial)
  };

  refs.form.current = form;

  refs.parent.current = parent;

  refs.search.current = search;

  refs.queryDefault.current = queryDefault;

  refs.queryObjectsName.current = queryObjectsName;

  refs.onQueryAfter.current = onQueryAfter;

  refs.onSearchProps.current = onSearchProps;

  refs.queryInitial.current = queryInitial;

  React.useEffect(() => {
    if (service) {
      (service as typeof UserService).search.subscribe(item => {
        if (Object.keys(item || {}).length) {
          refs.form.current.onChange(Object.keys(item || {}).map(property => [
            'input',
            item[property],
            property
          ]));
        }
      });
    }
  }, [service]);

  React.useEffect(() => {
    if (service) {
      (service as typeof UserService).search.emit(queryParams);
    }
  }, [hash(queryParams)]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 440);
  }, [queryObjects?.loaded]);

  const getDefault = React.useCallback((item: any) => {
    if (['select', 'objects'].includes(item.type)) return item.multiple ? [] : null;

    if (item.type === 'date') return [];

    return '';
  }, []);

  const getItem = React.useCallback((item: ISearchItem, index: number) => {
    const valueDefault = getDefault(item);
    const valueProperty = refs.form.current.value.input?.[item.property];

    const otherProps: any = {
      key: index + item.name,

      name: item.name,

      value: valueProperty !== undefined ? valueProperty : valueDefault,

      onChange: (valueNew: any) => refs.form.current.onChange('input', valueNew, item.property),

      size: 'small',

      ...item.props
    };

    if (valueProperty === valueDefault && valueProperty === null) otherProps.enabled = true;

    if ([undefined, 'text'].includes(item.type)) {
      return (
        <TextField
          clear

          {...otherProps}
        />
      );
    }

    if (item.type === 'select') {
      return (
        <Select
          options={item.options || []}

          multiple={item.multiple}

          {...otherProps}
        />
      );
    }

    if (item.type === 'date') {
      return (
        <Date
          clear

          {...otherProps}
        />
      );
    }

    if (item.type === 'objects') {
      return (
        <AutoCompleteObjects
          clear

          {...otherProps}
        />
      );
    }

    return null;
  }, []);

  const onSearch = React.useCallback(debounce(async () => {
    const value = refs.form.current.value.input;

    const body: any = {
      ...refs.queryDefault.current,

      ...refs.queryInitial.current,

      ...value
    };

    refs.search.current?.forEach(item => {
      const property = item.property;

      // objects 
      if (item.type === 'objects') {
        if (body[property]) body[property] = (is('array', body[property]) ? body[property] : [body.property]).filter(Boolean).map(item => item.id);
      }

      // dates  
      if (item.type === 'date') {
        if (body[property]) {
          const [from, to] = value[property];

          if (from) body[`${property}_from`] = from;

          if (to) body[`${property}_to`] = to;

          delete body[property];
        }
      }
    });

    Object.keys(body).forEach(key => {
      if (['', undefined, null].includes(body[key])) delete body[key];
    });

    if (is('function', refs.onSearchProps.current)) return refs.onSearchProps.current(body);

    const result = await service[refs.queryObjectsName.current].value!.query({
      id: refs.parent.current?.id,

      query: {
        ...service[refs.queryObjectsName.current].value!.previousQuery,

        query: {
          ...body,

          // ...(refs.pinned.current && { pinned: false })
        },

        sort: {
          // ...refs.sort.current
        },

        next: undefined,
        previous: undefined,
        skip: undefined,
        total: undefined
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    } else {
      if (is('function', refs.onQueryAfter.current)) await refs.onQueryAfter.current!(result);
    }
  }, timeout), []);

  React.useEffect(() => {
    if (loaded) onSearch();
  }, [hash(form.value)]);

  const withValues = React.useMemo(() => {
    const valueForm = copy(refs.form.current.value.input || {});

    Object.keys(valueForm || {}).forEach(key => {
      const itemSearch = refs.search.current?.find(item => item.property === key);

      if (
        (['', undefined, null].includes(valueForm[key])) &&
        !(itemSearch?.default === valueForm[key] || (itemSearch?.type === 'select' && itemSearch?.options?.find(item => item?.value === valueForm[key])))
      ) delete valueForm[key];
    });

    Object.keys(queryDefault || {}).forEach(key => delete valueForm[key]);

    Object.keys(queryInitial || {}).forEach(key => {
      if (queryInitial[key] === valueForm[key]) delete valueForm[key];
    });

    return Object.values(valueForm).some((item: any) => (
      item !== undefined &&
      ((is('array', item) || is('string', item)) ? item.length : true)
    ));
  }, [queryDefault, queryInitial, form]);

  if (!(search || service)) return null;

  return (
    <Line
      gap={1.5}

      direction='row'

      align='center'

      fullWidth

      className={classNames([
        classes.root,
        classes.actions
      ])}

      {...other}
    >
      {search?.map((item, index) => getItem(item, index))}

      {withValues && (
        <Tooltip
          name='Clear'
        >
          <IconButton
            onClick={() => form.clear()}
          >
            <IconMaterialClose
              size='large'
            />
          </IconButton>
        </Tooltip>
      )}
    </Line>
  );
});

export default Element;
