import { Discount } from '@onesy/api';

import { BaseService } from './base';

class DiscountService extends BaseService<Discount> {

  get paginationLimit() { return 50; }

  public constructor() {
    super('discounts');
  }

}

export default new DiscountService();
