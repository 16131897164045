import React from 'react';

import { innerHTMLToText, is, textToInnerHTML } from '@onesy/utils';
import { Checkbox, DateTimePicker, Label, Labels, Radio, Radios, Rating, Slider, TimePicker, Type, useForm, useSnackbars, useSubscription } from '@onesy/ui-react';
import { Form, IFormInput } from '@onesy/api';

import { DatePicker, Input, Inputs, ModalForm, Select, TextField } from 'ui';
import { AppService, AuthService, FormService } from 'services';
import { getErrorMessage } from 'utils';
import { ISignedIn } from 'types';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    form: objectForm
  } = props;

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const snackbars = useSnackbars();

  const [loading, setLoading] = React.useState(false);

  const form = useForm({
    values: {
      'response': {
        name: 'Response',
        value: objectForm.inputs.map(item => ({
          id: item.id,
          value: ''
        })),
        is: 'array',
        of: 'object'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      apps: ['personal-trainer']
    };

    const result = await FormService.addResponse(objectForm.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: 'Form response added'
      });

      onClose();
    }

    setLoading(false);
  }, [objectForm, form, signedIn, onClose]);

  const getInput = (input: IFormInput, index: number) => {
    if (!input) return null;

    const type = input.type;

    const propsInput: any = {
      key: index,

      onChange: (valueNew_: any) => {
        let valueNew = valueNew_;

        if (['date-time', 'date', 'time'].includes(type)) valueNew = valueNew ? valueNew.milliseconds : null;

        if (['text'].includes(input.type) && input.multiline) valueNew = innerHTMLToText(valueNew);

        refs.form.current.onChange('response', valueNew, `${index}.value`);
      },

      fullWidth: true
    };

    let Element: any = null;

    // text
    if (type === 'text') {
      Element = <TextField
        placeholder={input.name}

        multiline={input.multiline}

        {...input.multiline && {
          minRows: 3,
          maxRows: 4
        }}
      />;
    }

    // slider
    if (type === 'slider') {
      Element = (
        <Slider
          valueDefault={input.max}

          min={is('number', input.min) ? input.min : 1}

          max={is('number', input.max) ? input.max : 10}

          marks={[
            {
              value: is('number', input.min) ? input.min : 1,
              label: input.label_start && (
                <Type
                  version='b2'

                  style={{
                    whiteSpace: 'nowrap'
                  }}
                >
                  {input.label_start}
                </Type>
              )
            },
            {
              value: is('number', input.max) ? input.max : 10,
              label: input.label_end && (
                <Type
                  version='b2'

                  style={{
                    whiteSpace: 'nowrap'
                  }}
                >
                  {input.label_end}
                </Type>
              )
            }
          ]}

          orientation='horizontal'

          labels

          tooltip

          style={{
            marginLeft: 16
          }}
        />
      );
    }

    // radios
    if (type === 'radios') {
      Element = (
        <Radios>
          {(input.options || []).map((option: any) => (
            <Label
              key={option.id}

              value={option.value}

              Component='div'
            >
              <Radio />

              <Type
                version='b1'
              >
                {option.value}
              </Type>
            </Label>
          ))}
        </Radios>
      );
    }

    // checkboxes
    if (type === 'checkboxes') {
      Element = (
        <Labels>
          {(input.options || []).map((option: any) => (
            <Label
              key={option.id}

              value={option.value}

              Component='div'
            >
              <Checkbox />

              <Type
                version='b1'
              >
                {option.value}
              </Type>
            </Label>
          ))}
        </Labels>
      );
    }

    // date-time
    if (type === 'date-time') {
      Element = (
        <DateTimePicker
          size='small'
        />
      );
    }

    // date
    if (type === 'date') {
      Element = (
        <DatePicker
          size='small'
        />
      );
    }

    // time
    if (type === 'time') {
      Element = (
        <TimePicker
          size='small'
        />
      );
    }

    // select
    if (type === 'select' && input.options?.length) {
      Element = (
        <Select
          name={`Select ${input.multiple ? 'one or multiple options' : 'one option'}`}

          options={input.options.map(item => ({
            name: item?.value,
            value: item?.value
          }))}

          multiple={input.multiple}
        />
      );
    }

    // rating
    if (type === 'rating') {
      Element = (
        <Rating
          values={is('number', input.max) ? input.max : 5}

          precision={1}

          size='large'
        />
      );
    }

    // confirm
    if (type === 'confirm') {
      Element = (
        <Label>
          <Checkbox />

          <Type
            version='b1'
          >
            {input.confirm_text || 'Confirm'}
          </Type>
        </Label>
      );
    }

    if (!Element) return null;

    return (
      <Input
        key={index}

        gap={1}

        name={input.name}

        description={textToInnerHTML(input.description)}
      >
        {React.cloneElement(Element, {
          ...propsInput
        })}
      </Input>
    );
  };

  const modal: any = {
    write: (
      <Inputs>
        {(objectForm as Form).inputs?.map((input, index) => getInput(input, index))}
      </Inputs>
    )
  };

  return (
    <ModalForm
      {...props}

      name={`Fill the ${objectForm.name} form`}

      add

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    />
  );
});

export default Element;