import React from 'react';
import { useNavigate } from 'react-router-dom';

import { is } from '@onesy/utils';
import { Expand, Line, Tab, Tabs, Type, useLocation, useSubscription } from '@onesy/ui-react';
import { classNames, style } from '@onesy/style-react';

import IconMaterialStarRate from '@onesy/icons-material-rounded-react/IconMaterialStarRateW100';
import IconMaterialGrocery from '@onesy/icons-material-rounded-react/IconMaterialGroceryW100';
import IconMaterialExercise from '@onesy/icons-material-rounded-react/IconMaterialExerciseW100';
import IconMaterialListAlt from '@onesy/icons-material-rounded-react/IconMaterialListAltW100';
import IconMaterialFlagCircle from '@onesy/icons-material-rounded-react/IconMaterialFlagCircleW100';
import IconMaterialAssignment from '@onesy/icons-material-rounded-react/IconMaterialAssignmentW100';
import IconMaterialCircle from '@onesy/icons-material-rounded-react/IconMaterialCircleW100Filled';
import IconMaterialCalendarMonth from '@onesy/icons-material-rounded-react/IconMaterialCalendarMonthW100';
import IconMaterialCalendarToday from '@onesy/icons-material-rounded-react/IconMaterialCalendarTodayW100';
import IconMaterialInsertChart from '@onesy/icons-material-rounded-react/IconMaterialInsertChartW100';
import IconMaterialChatBubble from '@onesy/icons-material-rounded-react/IconMaterialChatBubbleW100';

import { ReactComponent as Logo } from 'assets/svg/logos/logo-personal-trainer.svg';

import { UserMenu } from 'ui';
import { AuthService } from 'services';
import { getRootPage, isHex } from 'utils';
import { ISignedIn } from 'types';

const useStyle = style(theme => ({
  root: {
    flex: '0 0 auto',
    zIndex: 14
  },

  header: {
    position: 'relative',
    padding: 16,
    flex: '0 0 auto'
  },

  logo: {
    '& > svg, & > img': {
      height: 44,
      width: 'auto',
      cursor: 'pointer',
      userSelect: 'none',
      transition: theme.methods.transitions.make('transform', { duration: 'xs' }),

      '&:active': {
        transform: 'scale(0.94)'
      }
    }
  },

  tabsMain: {
    '&.onesy-Tabs-root': {
      width: 'auto',
      flex: '1 1 auto'
    }
  },

  tabs: {
    '& .onesy-Tabs-line': {
      height: 2
    }
  },

  tab: {
    '&.onesy-Tab-root': {
      padding: '8px 20px'
    }
  }
}), { name: 'onesy-Header' });

const Header = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const navigate = useNavigate();
  const location = useLocation();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [page, setPage] = React.useState<any>();
  const [open, setOpen] = React.useState(false);
  const [subRoutes, setSubRoutes] = React.useState([]);

  const refs = {
    root: React.useRef<any>(undefined)
  };

  const user = signedIn?.user;

  const isUser = user?.is?.user;

  const routesMain = React.useMemo(() => {

    return [
      { name: 'Overview', to: '/', Icon: IconMaterialInsertChart },
      { name: 'Calendar', to: '/calendar', Icon: IconMaterialCalendarToday },
      { name: 'Availability', to: '/available', Icon: IconMaterialCircle, propsIcon: { color: 'success', size: 15 } },
      { name: 'Goals', to: '/goals', Icon: IconMaterialFlagCircle },
      { name: 'Workout plans', to: '/workout-plans', Icon: IconMaterialExercise },
      { name: 'Nutrition plans', to: '/nutrition-plans', Icon: IconMaterialGrocery },
      { name: 'Assesments', to: '/assessments', Icon: IconMaterialAssignment },
      { name: 'Forms', to: '/forms', Icon: IconMaterialListAlt },
      { name: 'Chat', to: '/chats', Icon: IconMaterialChatBubble },
      { name: 'Review', to: '/review', Icon: IconMaterialStarRate }
    ];
  }, [signedIn]);

  const routes = React.useMemo(() => {

    return {
      calendar: isUser ? [
        { name: 'Bookings', to: '/calendar', Icon: IconMaterialCalendarMonth },
        { name: 'Availability', to: '/calendar/available', Icon: IconMaterialCircle, propsIcon: { color: 'success', size: 15 } }
      ] : []
    };
  }, [signedIn]);

  const routesMap = React.useMemo(() => {
    const map = {};

    Object.keys(routes).forEach(key => {
      map[key.replace('/', '')] = key;

      routes[key].forEach(item => map[item.to.replace('/', '')] = key);
    });

    return map;
  }, [routes]);

  React.useEffect(() => {
    const pathname = location.pathname;

    let page_ = pathname.split('/').filter(Boolean)[0];

    if (pathname.startsWith('/organization/settings')) page_ = 'organization_settings';

    const pageRoute = routesMap[page_];

    const menus = routes[pageRoute];

    if (menus?.length) setSubRoutes(menus);

    setPage(page_);
  }, [routesMap, location]);

  const isActive = React.useCallback((pathname: string, route: string) => {
    const pageRoute = routesMap[page];

    const routeMain = routesMain.find(item => item.name.toLowerCase() === pageRoute);

    const end = pathname.replace(`${route}/`, '');

    return !!(
      routeMain?.to === route ||
      route === `/${pageRoute}` ||
      pathname === route ||
      (pathname.startsWith(route) && (end === 'add' || isHex(`0x${end}`)))
    );
  }, [page, routesMain]);

  const isActiveSubRoute = React.useCallback((pathname: string, route: string) => {
    const end = pathname.replace(`${route}/`, '');

    return !!(
      pathname === route ||
      (pathname.startsWith(route) && (end === 'add' || isHex(`0x${end}`)))
    );
  }, [page]);

  React.useEffect(() => {
    const pageRoute = routesMap[page];

    const menus = routes[pageRoute];

    setOpen(!!menus?.length);
  }, [page]);

  const logo = signedIn?.organization?.settings?.logos?.logo_light;

  const iconProps = {
    size: 'large'
  };

  return (
    <Line
      ref={(item: any) => {
        refs.root.current = item;

        if (ref) {
          if (is('function', ref)) ref(item)
          else ref.current = item;
        }
      }}

      gap={0}

      direction='column'

      justify='unset'

      align='unset'

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      {/* Header */}
      <Line
        gap={2}

        direction='row'

        justify='space-between'

        align='center'

        fullWidth

        className={classNames([
          classes.header
        ])}
      >
        <Line
          gap={2}

          direction='row'

          align='center'

          flex
        >
          <Line
            className={classes.logo}
          >
            {logo ? (
              <img
                src={logo.url}

                onClick={() => navigate(getRootPage(signedIn))}

                alt='Logo'
              />
            ) : (
              <Logo
                onClick={() => navigate(getRootPage(signedIn))}
              />
            )}
          </Line>
        </Line>

        <Line
          gap={1.5}

          direction='row'

          align='center'
        >
          <UserMenu />
        </Line>
      </Line>

      <Line
        gap={0.5}

        direction='column'

        fullWidth
      >
        {/* Main menus */}
        <Line
          gap={0}

          direction='row'

          justify='unset'

          align='center'

          fullWidth
        >
          <Tabs
            value={location.pathname}

            isActive={isActive}

            size='large'

            noDivider

            className={classes.tabs}
          >
            {routesMain?.map((item: any) => (
              <Tab
                key={item.to}

                onClick={() => item.link ? window.open(item.link, 'blank') : navigate(item.to)}

                value={item.to}

                icon={item.Icon ? <item.Icon {...item.propsIcon} /> : null}

                className={classes.tab}
              >
                <Type
                  version='t1'
                >
                  {item.name}
                </Type>

                {item.IconEnd ? <item.IconEnd size='regular' {...item.propsIcon} /> : null}
              </Tab>
            ))}
          </Tabs>
        </Line>

        {/* Page menus */}
        <Expand
          in={open}
        >
          <Line
            gap={0}

            direction='row'

            justify='unset'

            align='center'

            fullWidth
          >
            <Tabs
              value={location.pathname}

              isActive={isActiveSubRoute}

              size='large'

              noDivider

              className={classes.tabs}
            >
              {subRoutes?.map((item: any) => (
                <Tab
                  key={item.to}

                  onClick={() => navigate(item.to)}

                  value={item.to}

                  icon={item.Icon ? <item.Icon {...iconProps} {...item.propsIcon} /> : null}

                  className={classes.tab}
                >
                  <Type
                    version='t1'
                  >
                    {item.name}
                  </Type>
                </Tab>
              ))}
            </Tabs>
          </Line>
        </Expand>
      </Line>
    </Line>
  );
});

export default Header;
