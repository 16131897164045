import { Contact } from '@onesy/api';

import { BaseService } from './base';

class ContactService extends BaseService<Contact> {

  get paginationLimit() {
    return 'all';
  }

  public constructor() {
    super('contacts');
  }

}

export default new ContactService();
