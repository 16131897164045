import { NutritionPlan } from '@onesy/api';

import { BaseService } from './base';

class NutritionPlanService extends BaseService<NutritionPlan> {

  get paginationLimit() {
    return 'all';
  }

  public constructor() {
    super('nutritionPlans');
  }

}

export default new NutritionPlanService();
