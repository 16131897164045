import OnesyLog from '@onesy/log';

const onesyLog = new OnesyLog({
  arguments: {
    pre: [
      'onesy app'
    ]
  }
});

export default onesyLog;
