import { Form, FormResponse } from '@onesy/api';
import { Query } from '@onesy/models';
import OnesySubscription from '@onesy/subscription';
import { IAdditional, IResponse } from '@onesy/sdk/other';

import { MainService } from 'services';
import { IQuerySubscription } from '../types';
import { BaseService } from './base';

class FormService extends BaseService<Form> {
  public queryObjectsResponses = new OnesySubscription<IQuerySubscription>();

  public constructor() {
    super('forms');

    this.queryObjectsInit(this.queryObjectsResponses, 'queryResponsePost');
  }

  public async addResponse(id: string, body: Partial<FormResponse>, options?: IAdditional): Promise<IResponse> {
    return MainService.sdk.forms.addResponse(id, body as any, options);
  }

  public async getResponse(id: string, id_form_response: string, options?: IAdditional): Promise<IResponse> {
    return MainService.sdk.forms.getResponse(id, id_form_response, options);
  }

  public async queryResponse(id: string, options?: IAdditional): Promise<IResponse> {
    return MainService.sdk.forms.queryResponse(id, options);
  }

  public async queryResponsePost(id: string, body?: Partial<Query>, options?: IAdditional): Promise<IResponse> {
    return MainService.sdk.forms.queryResponsePost(id, body, options);
  }

  public async updateResponse(id: string, id_form_response: string, body: any, options?: IAdditional): Promise<IResponse> {
    return MainService.sdk.forms.updateResponse(id, id_form_response, body, options);
  }

  public async removeResponse(id: string, id_form_response: string, options?: IAdditional): Promise<IResponse> {
    return MainService.sdk.forms.removeResponse(id, id_form_response, options);
  }

}

export default new FormService();