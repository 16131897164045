import { Exercise } from '@onesy/api';

import { BaseService } from './base';

class ExerciseService extends BaseService<Exercise> {

  get paginationLimit() {
    return 30;
  }

  public constructor() {
    super('exercises');
  }

}

export default new ExerciseService();
