import React from 'react';

import { Line, Type } from '@onesy/ui-react';
import { classNames, style } from '@onesy/style-react';
import { IBaseElement } from '@onesy/ui-react/types';
import { IMedia } from '@onesy/api';

const useStyle = style(theme => ({
  root: {
    width: '100%',
    maxWidth: 335,
    borderRadius: 8,
    overflow: 'hidden',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.07)'
  },

  color_primary: {
    backgroundColor: theme.palette.color.primary[theme.palette.light ? 100 : 5],

    '& $itemIcon': {
      backgroundColor: theme.palette.color.primary[theme.palette.light ? 100 : 5]
    }
  },

  color_secondary: {
    backgroundColor: theme.palette.color.primary[theme.palette.light ? 100 : 1],

    '& $itemIcon': {
      backgroundColor: theme.palette.color.primary[theme.palette.light ? 100 : 1]
    }
  },

  image: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '100%',
    aspectRatio: '3 / 2',
    backgroundColor: theme.palette.background.default.primary,
    borderBottom: `1px solid ${theme.palette.light ? '#ededed' : '#373737'}`
  },

  imageOther: {
    backgroundPosition: '50% 85%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '84%',
    width: '100%',
    aspectRatio: '3 / 2',
    backgroundColor: theme.palette.background.primary.tertiary
  },

  main: {
    padding: 20
  },

  name: {
    cursor: 'pointer'
  },

  selected: {
    outline: `2px solid ${theme.palette.text.default.primary}`
  },

  ...theme.classes(theme)
}), { name: 'onesy-ItemCardBox' });

export interface IItemCardBox extends IBaseElement {
  object?: IMedia;

  color?: 'primary' | 'secondary';

  name: string;

  meta?: any;

  image?: string;

  cover?: boolean;

  startFooter?: any;

  endFooter?: any;

  selected?: boolean;

  select?: boolean;

  onOpen?: (...args: any) => any;

  onSelect?: (...args: any) => any;
}

const Element: React.FC<IItemCardBox> = React.forwardRef((props, ref: any) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    object = {},

    color = 'primary',

    name,

    meta,

    image,

    startFooter,

    endFooter,

    onOpen,

    onSelect,

    cover,

    selected,

    select,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  return (
    <Line
      ref={ref}

      gap={0}

      align='unset'

      justify='unset'

      className={classNames([
        className,
        classes.root,
        classes[`color_${color}`],
        selected && classes.selected
      ])}

      {...other}
    >
      <Line
        onClick={select ? onSelect : onOpen}

        className={classNames([
          image ? classes.image : classes.imageOther,
        ])}

        style={{
          backgroundImage: `url(${image})`,

          ...(cover && {
            backgroundSize: 'cover'
          })
        }}
      />

      <Line
        gap={2}

        direction='column'

        flex

        fullWidth

        className={classes.main}
      >
        <Line
          gap={0.5}

          fullWidth
        >
          {name && (
            <Type
              version='t2'

              onClick={onOpen}

              className={classes.name}
            >
              {name}
            </Type>
          )}

          {meta && (
            <Type
              version='b2'
            >
              {meta}
            </Type>
          )}
        </Line>

        <Line
          direction='row'

          align='flex-end'

          justify='space-between'

          flex

          fullWidth
        >
          <Line
            gap={0.5}

            direction='row'

            align='center'
          >
            {startFooter}
          </Line>

          <Line
            gap={0.5}

            direction='row'

            align='center'
          >
            {endFooter}
          </Line>
        </Line>
      </Line>
    </Line>
  );
});

export default Element;
