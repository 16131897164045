import OnesySubscription from '@onesy/subscription';
import { Purchase } from '@onesy/api';

import { BaseService } from './base';
import { IQuerySubscription } from 'types';

class PurchaseService extends BaseService<Purchase> {
  public queryObjectsPackages = new OnesySubscription<IQuerySubscription>();
  public queryObjectsMemberships = new OnesySubscription<IQuerySubscription>();

  get paginationLimit() {
    return 100;
  }

  public constructor() {
    super('purchases');

    this.queryObjectsInit(this.queryObjectsPackages);

    this.queryObjectsInit(this.queryObjectsMemberships);
  }

}

export default new PurchaseService();
