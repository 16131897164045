import { Assessment } from '@onesy/api';

import { BaseService } from './base';

class AssessmentService extends BaseService<Assessment> {

  get paginationLimit() {
    return 'all';
  }

  public constructor() {
    super('assessments');
  }

}

export default new AssessmentService();
