import { Page } from '@onesy/api';

import { BaseService } from './base';

class PageService extends BaseService<Page> {

  get paginationLimit() {
    return 'all';
  }

  public constructor() {
    super('pages');
  }

}

export default new PageService();
