import React from 'react';

import { cleanValue, copy, is, textToInnerHTML } from '@onesy/utils';
import { IconButton, Line, Modal, Slide, Switch, Tooltip, Type } from '@onesy/ui-react';
import { IBaseElement, ISize } from '@onesy/ui-react/types';
import { classNames, style } from '@onesy/style-react';

import IconMaterialEdit from '@onesy/icons-material-rounded-react/IconMaterialEditW100';

import { ReactComponent as Logo } from 'assets/svg/logo.svg';

import { ModalForm } from 'ui/layout';
import { Repeat } from '.';

const useStyle = style(theme => ({
  root: {
    boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.04)',
    borderRadius: 0,

    '& .onesy-Type-root': {
      whiteSpace: 'normal'
    }
  },

  color_primary: {
    background: theme.palette.color.primary[theme.palette.light ? 100 : 5],

    '& $itemIcon': {
      background: theme.palette.color.primary[theme.palette.light ? 100 : 5]
    }
  },

  color_secondary: {
    background: theme.palette.color.primary[theme.palette.light ? 100 : 1],

    '& $itemIcon': {
      background: theme.palette.color.primary[theme.palette.light ? 100 : 1]
    }
  },

  onesy: {
    height: 27,
    width: 'auto'
  },

  onesySpan: {
    alignSelf: 'center',
    lineHeight: 0
  },

  item: {
    padding: 24
  },

  item_size_small: {
    padding: 16
  },

  item_size_regular: {
    padding: 24
  },

  item_size_large: {
    padding: 32
  },

  size_small: {
    minHeight: 80
  },

  size_regular: {
    minHeight: 100
  },

  size_large: {
    minHeight: 120
  },

  itemIcon: {
    aspectRatio: '1 / 1'
  },

  itemImage: {
    aspectRatio: '1 / 1',
    backgroundSize: 'cover',
    backgroundPosition: 'top center'
  },

  itemValue: {
    '&.onesy-Type-root': {
      maxWidth: '100%',
      wordBreak: 'break-all',
      whiteSpace: 'normal'
    }
  },

  itemValueAction: {
    cursor: 'pointer',
    userSelect: 'none'
  },

  itemOther: {
    '& > *': {
      flex: '0 0 auto'
    }
  },

  surface: {
    '&.onesy-Surface-root': {
      padding: 0,
      background: theme.palette.color.primary[theme.palette.light ? 99 : 5]
    }
  },

  maxHeight: {
    height: 'calc(100% - 80px)'
  }
}), { name: 'onesy-ItemCard' });

export interface IItemCardItem {
  object?: any;

  color?: 'primary' | 'secondary';

  url?: string;

  icon?: any;

  entire?: boolean;

  name?: any;

  value?: any;

  align?: 'flex-start' | 'center' | 'flex-end';

  onClick?: any;

  onUpdate?: (property: string, value: any) => any;

  onesy?: boolean;

  original?: boolean;

  repeat?: boolean;

  props?: any;

  propsValue?: any;

  propsName?: any;

  propsIcon?: any;
}

export interface IItemCard extends IBaseElement {
  size?: ISize;

  items_start: IItemCardItem[];

  items_end?: IItemCardItem[];
}

const Element: React.FC<IItemCard> = React.forwardRef((props, ref: any) => {
  const {
    color = 'primary',

    size = 'small',

    items_start,

    items_end,

    RepeatProps,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const [openModal, setOpenModal] = React.useState<any>(false);

  const propertyRepeat = RepeatProps?.property || 'repeat';

  const nameRepeat = cleanValue(propertyRepeat?.replace('price', 'payment'), { capitalize: true });

  const onOpenModal = React.useCallback((value: any = { open: true }) => {
    setOpenModal(value);
  }, []);

  const onCloseModal = React.useCallback(() => {
    setOpenModal((item: any) => ({ ...item, open: false }));
  }, []);

  const getItem = (item: IItemCardItem, index: number) => {
    const {
      original,
      align,
      repeat,
      object,
      onUpdate
    } = item;

    const otherProps = {
      key: index,

      ...item.props,

      style: {
        ...(align && {
          alignSelf: align
        }),

        ...item.props?.style
      }
    };

    if (item.url !== undefined) {
      return (
        <Line
          {...otherProps}

          onClick={item.onClick}

          className={classNames([
            otherProps.className,

            classes.itemImage,
            classes[`size_${size}`],
            item.onClick && classes.itemValueAction
          ])}

          style={{
            backgroundImage: `url(${item.url})`,

            ...otherProps.style
          }}
        />
      );
    }

    if (item.icon !== undefined) {
      return (
        <Line
          {...otherProps}

          onClick={item.onClick}

          align='center'

          justify='center'

          className={classNames([
            otherProps.className,

            classes.itemIcon,
            classes[`size_${size}`],
            item.onClick && classes.itemValueAction
          ])}
        >
          <item.icon
            size='large'

            {...item.propsIcon}
          />
        </Line>
      );
    }

    if (repeat) {
      const valueRepeat = object?.[propertyRepeat]?.value || 1;

      const endText = object?.[propertyRepeat]?.active ? ` every ${valueRepeat} ${object?.[propertyRepeat]?.unit}${valueRepeat === 1 ? '' : 's'}` : '';

      return (
        <Line
          gap={0.5}

          align='flex-end'

          justify='center'

          {...otherProps}

          className={classNames([
            'onesy-ItemCard-item',
            otherProps.className,

            classes.itemOther,

            ...(!item.entire ? [
              classes.item,
              classes[`item_size_${size}`],
              classes[`size_${size}`]
            ] : [])
          ])}
        >
          <Type
            version={size === 'large' ? 'b1' : size === 'regular' ? 'b2' : 'b3'}

            whiteSpace='nowrap'

            dangerouslySetInnerHTML={{
              __html: `${textToInnerHTML(item.name || nameRepeat)}${endText}`
            }}

            {...item.propsName}
          />

          <Line
            gap={0.5}

            direction='row'

            align='center'
          >
            {!RepeatProps?.noActive && (
              <Switch
                value={object?.[propertyRepeat]?.active}

                onChange={(valueNew: any) => onUpdate?.(propertyRepeat, {
                  ...object[propertyRepeat],

                  active: valueNew
                })}

                size='small'
              />
            )}

            <Tooltip
              name={`Edit ${cleanValue(propertyRepeat)}`}
            >
              <IconButton
                onClick={() => onOpenModal({ object: copy(object), onUpdate, open: true })}

                size='small'
              >
                <IconMaterialEdit />
              </IconButton>
            </Tooltip>
          </Line>
        </Line>
      );
    }

    if (item.onesy) return (
      <Tooltip
        name='Public'
      >
        <span
          className={classes.onesySpan}
        >
          <Logo
            className={classes.onesy}
          />
        </span>
      </Tooltip>
    );

    return (
      <Line
        gap={0.5}

        align='unset'

        justify='center'

        {...otherProps}

        className={classNames([
          'onesy-ItemCard-item',
          otherProps.className,

          classes.itemOther,

          ...(!item.entire ? [
            classes.item,
            classes[`item_size_${size}`],
            classes[`size_${size}`]
          ] : [])
        ])}
      >
        {is('string', item.name) ? (
          <Type
            version={size === 'large' ? 't3' : size === 'regular' ? 'b1' : 'b2'}

            weight={200}

            whiteSpace='nowrap'

            dangerouslySetInnerHTML={{
              __html: textToInnerHTML(item.name)
            }}

            {...item.propsName}
          />
        ) : item.name}

        {is('simple', item.value) ? (
          <Type
            version={size === 'large' ? 'h3' : size === 'regular' ? 't1' : 't2'}

            weight={600}

            onClick={item.onClick}

            dangerouslySetInnerHTML={{
              __html: original ? item.value : textToInnerHTML(item.value)
            }}

            {...item.propsValue}

            className={classNames([
              classes.itemValue,
              item.propsValue?.className,
              item.onClick && classes.itemValueAction
            ])}
          />
        ) : item.value}
      </Line>
    );
  };

  const methodFilter = React.useCallback((item: any) => [item.onesy, item.url, item.icon, item.object, item.value].some(item_ => item_ !== undefined), []);

  return <>
    <Line
      ref={ref}

      gap={0}

      direction='row'

      wrap='wrap'

      align='flex-start'

      justify='space-between'

      fullWidth

      className={classNames([
        'onesy-ItemCard',

        className,
        classes.root,
        classes[`color_${color}`]
      ])}

      {...other}
    >
      <Line
        gap={0}

        direction='row'

        wrap='wrap'

        justify='flex-start'

        align='stretch'
      >
        {items_start?.filter(methodFilter).map((item, index) => getItem(item, index))}
      </Line>

      <Line
        gap={0}

        direction='row'

        wrap='wrap'

        justify='flex-end'

        align='stretch'

        flex

        style={{
          alignSelf: 'flex-end'
        }}
      >
        {items_end?.filter(methodFilter).map((item, index) => getItem(item, index))}
      </Line>
    </Line>

    <Modal
      open={openModal?.open}

      minWidth='lg'

      onOpen={onOpenModal}

      onClose={onCloseModal}

      TransitionComponent={Slide}

      SurfaceProps={{
        tonal: true,
        color: 'primary',

        className: classNames([
          classes.surface
        ])
      }}
    >
      <ModalForm
        name={`${nameRepeat} update`}

        onClose={onCloseModal}
      >
        <Repeat
          object={openModal.object}

          {...RepeatProps}

          onUpdate={openModal.onUpdate}

          onClose={onCloseModal}
        />
      </ModalForm>
    </Modal>
  </>;
});

export default Element;
