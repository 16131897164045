import React from 'react';

import { CalendarViews } from '@onesy/ui-react';
import { ICalendarViews } from '@onesy/ui-react/CalendarViews/CalendarViews';

const Element: React.FC<ICalendarViews> = props => {

  return (
    <CalendarViews
      {...props}
    />
  );
};

export default Element;
