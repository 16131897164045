import React from 'react';

import { setObjectValue } from '@onesy/utils';
import { Line, Switch, Type } from '@onesy/ui-react';
import { classNames, style } from '@onesy/style-react';

import { Input, NumericTextField, Select } from 'ui';
import { optionsSessionsClassesUnit } from 'utils';

const useStyle = style(theme => ({
  root: {
    '& .onesy-TextField-input-wrapper, & .onesy-TextField-sufix': {
      paddingBlock: '4px !important'
    }
  }
}), { name: 'onesy-QuantityForm' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    form,

    object,

    property = 'spaces',

    sufix,

    onUpdate,

    rerender = true,

    noUnit,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const update = React.useCallback((property: string, valueNew: any, propertyNested: any, other?: any) => {
    if (form) form.onChange(property, valueNew, propertyNested, other);
    else if (object !== undefined && onUpdate) {
      const item = object || {};

      setObjectValue(item, propertyNested, valueNew);

      onUpdate(property, item);
    }
  }, [form, object, onUpdate]);

  return (
    <Input
      name='Spaces'

      endHeader={(
        <Line
          gap={1}

          direction='row'

          wrap='wrap'

          align='center'

          justify={{
            default: 'flex-start',
            700: 'flex-end'
          }}

          fullWidth={{
            default: false,
            700: true
          }}

          flexNo
        >
          <NumericTextField
            version='text'

            placeholder='10'

            {...rerender ? {
              value: object !== undefined ? object?.value : form?.values?.[property]?.value?.value
            } : {
              valueDefault: object !== undefined ? object?.value : form?.values?.[property]?.value?.value
            }}

            onChange={(valueNew: any) => update(property, valueNew, 'value')}

            min={0}

            sufix={sufix !== undefined ? sufix : property}

            align='end'

            enabled

            minimal

            fullWidth

            style={{
              width: 'auto',
              minWidth: 140
            }}
          />

          {!noUnit && <>
            <Type
              version='b1'

              weight={100}
            >
              per
            </Type>

            <Select
              version='text'

              {...rerender ? {
                value: object !== undefined ? object?.unit : form?.values?.[property]?.value?.unit
              } : {
                valueDefault: object !== undefined ? object?.unit : form?.values?.[property]?.value?.unit
              }}

              onChange={(valueNew: any) => update(property, valueNew, 'unit')}

              options={optionsSessionsClassesUnit}

              noSelectText='Unit'

              minimal

              fullWidth

              WrapperProps={{
                style: {
                  width: 60,
                  height: 'unset',
                  minWidth: 'unset'
                }
              }}

              style={{
                width: 60,
                height: 'unset',
                minWidth: 'unset'
              }}
            />
          </>}
        </Line>
      )}

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Input
        startName={(
          <Switch
            value={object !== undefined ? object?.unlimited : form?.values?.[property]?.value?.unlimited}

            onChange={(valueNew: any) => update(property, valueNew, 'unlimited')}

            size='small'
          />
        )}

        name='Unlimited'

        size='small'
      />
    </Input>
  );
});

export default Element;
