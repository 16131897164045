import React from 'react';

import { is } from '@onesy/utils';
import { Expand, IconButton, Line, Tooltip, Type } from '@onesy/ui-react';
import { IBaseElement, ISize } from '@onesy/ui-react/types';
import { useOnesyTheme } from '@onesy/style-react';

import IconMaterialExpandMore from '@onesy/icons-material-rounded-react/IconMaterialExpandMoreW100';

import { Divider } from '.';

export interface IInput extends IBaseElement {
  name?: any;

  description?: any;

  gapMain?: any;

  iconName?: any;

  iconDescription?: any;

  expand?: boolean;

  footer?: any;

  center?: boolean;

  maxWidth?: boolean | number;

  startName?: any;

  endName?: any;

  startHeader?: any;

  endHeader?: any;

  size?: ISize;

  weight?: number;

  divider?: boolean;

  NameProps?: any;

  DescriptionProps?: any;

  TypeProps?: any;

  MainProps?: any;

  EndProps?: any;
}

const Element: React.FC<IInput> = React.forwardRef((props, ref: any) => {
  const {
    name,

    description,

    size = 'regular',

    gapMain: gapMainProps,

    iconName,

    iconDescription,

    startName,

    endName,

    startHeader,

    endHeader,

    expand,

    footer,

    center,

    weight,

    maxWidth,

    divider,

    NameProps,

    DescriptionProps,

    TypeProps,

    MainProps,

    EndProps,

    children,

    className,

    style,

    ...other
  } = props;

  const theme = useOnesyTheme();

  const [expanded, setExpanded] = React.useState(!expand);

  const onExpand = React.useCallback(() => {
    setExpanded(item => !item);
  }, []);

  const items = React.Children.toArray(children);

  let gapMain = size === 'large' ? 4 : size === 'regular' ? 3 : 2;

  if (divider) gapMain = size === 'large' ? 3 : size === 'regular' ? 2 : 1;

  const main = (
    <Line
      gap={gapMainProps !== undefined ? gapMainProps : gapMain}

      fullWidth

      {...MainProps}
    >
      {items.flatMap((item, index) => !divider ? item : [item, index < items.length - 1 ? <Divider size='small' /> : null])}
    </Line>
  );

  const withHeader = name || description;

  return (
    <Line
      gap={size === 'large' ? 6 : size === 'regular' ? 4 : 2}

      fullWidth

      style={{
        ...(maxWidth !== undefined && {
          maxWidth: is('number', maxWidth) ? maxWidth : theme.maxWidth
        }),

        ...style
      }}

      {...other}
    >
      {withHeader && (
        <Line
          gap={2}

          direction={{
            default: 'row',
            700: 'column'
          }}

          align={{
            default: 'center',
            700: 'flex-start'
          }}

          fullWidth
        >
          {startHeader}

          <Line
            gap={0.5}

            fullWidth

            flex
          >
            {name && (
              <Line
                gap={2}

                direction='row'

                wrap='wrap'

                align='center'

                justify='space-between'

                fullWidth

                {...NameProps}
              >
                <Line
                  gap={2}

                  direction='row'

                  wrap='wrap'

                  align='center'
                >
                  {startName}

                  <Line
                    gap={1}

                    direction='row'

                    align='center'
                  >
                    {iconName}

                    {is('string', name) ? (
                      <Type
                        version={size === 'large' ? 'h2' : size === 'regular' ? 'h3' : 't1'}

                        weight={weight !== undefined ? weight : 500}

                        dangerouslySetInnerHTML={{
                          __html: name
                        }}

                        {...TypeProps}
                      />
                    ) : name}
                  </Line>
                </Line>

                {(expand || endName) && (
                  <Line
                    gap={1}

                    direction='row'

                    wrap='wrap'

                    align='center'

                    {...EndProps}

                    style={{
                      flex: '0 0 auto',

                      ...EndProps?.style
                    }}
                  >
                    {endName}

                    {expand && (
                      <Tooltip
                        name={expanded ? 'Hide' : 'Open'}
                      >
                        <IconButton
                          onClick={onExpand}
                        >
                          <IconMaterialExpandMore
                            size='large'

                            style={{
                              ...(expanded && { transform: 'rotate(180deg)' })
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Line>
                )}
              </Line>
            )}

            {description && (
              <Line
                gap={1}

                direction='row'

                align='center'

                {...DescriptionProps}
              >
                {iconDescription}

                {is('string', description) ? (
                  <Type
                    version={size === 'large' ? 'b1' : size === 'regular' ? 'b1' : 'b2'}

                    weight={200}

                    dangerouslySetInnerHTML={{
                      __html: description
                    }}
                  />
                ) : description}
              </Line>
            )}
          </Line>

          {endHeader}
        </Line>
      )}

      {children && <>
        {!expand ? main : (
          <Expand
            in={expanded}
          >
            {main}
          </Expand>
        )}
      </>}

      {footer && (
        <Line
          gap={1}

          direction='row'

          align='center'

          justify={center ? 'center' : 'flex-end'}

          fullWidth

          style={{
            marginTop: 10
          }}
        >
          {footer}
        </Line>
      )}
    </Line>
  );
});

export default Element;
