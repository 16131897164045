import React from 'react';
import * as Sentry from '@sentry/react';

import { Type, Link, Line } from '@onesy/ui-react';

export default class ErrorBoundary extends React.Component<any, any> {

  constructor(props: any) {
    super(props);

    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log('App error: ', error.name, error.message, error);

    this.setState({
      error: error,
      errorInfo: errorInfo
    });

    // Sentry 
    Sentry.captureException(error, {
      data: {
        app: 'App'
      }
    });
  }

  refresh() {
    window.location.reload();
  }

  render() {
    let Content: any = <></>;

    if (this.state.errorInfo) {
      if (this.props.fallback) Content = (
        <Line
          direction='column'
          align='center'
          justify='center'

          style={{
            width: '100%',
            height: '100%'
          }}
        >
          <Type
            version='b1'

            style={{
              textAlign: 'center'
            }}
          >
            Oops, something went wrong, <Link onClick={this.refresh}>refresh</Link>.
          </Type>
        </Line>
      );
      else Content = null;
    }
    else Content = this.props.children || null;

    return Content;
  }
}