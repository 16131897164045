import { Available } from '@onesy/api';

import { BaseService } from './base';

class AvailableService extends BaseService<Available> {

  get paginationLimit() {
    return 'all';
  }

  public constructor() {
    super('availables');
  }

}

export default new AvailableService();
