import { Workout } from '@onesy/api';

import { BaseService } from './base';

class WorkoutService extends BaseService<Workout> {

  get paginationLimit() {
    return 30;
  }

  public constructor() {
    super('workouts');
  }

}

export default new WorkoutService();
