import { IAdditional } from '@onesy/sdk/other';

import { MainService } from 'services';
import { BaseService } from './base';

class RootService extends BaseService {

  public constructor() {
    super('');
  }

  public async root(options?: IAdditional) {
    return MainService.sdk.root.root(options);
  }

  public async health(options?: IAdditional) {
    return MainService.sdk.root.health(options);
  }

}

export default new RootService();
