import { Notification } from '@onesy/api';
import { IAdditional, IResponse } from '@onesy/sdk/other';

import { BaseService } from './base';
import { MainService } from 'services';

class NotificationService extends BaseService<Notification> {

  public constructor() {
    super('notifications');
  }

  public async read(options?: IAdditional): Promise<IResponse> {
    return MainService.sdk.notifications.read(options);
  }

}

export default new NotificationService();
