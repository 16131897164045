import { Page, Website } from '@onesy/api';
import { Query } from '@onesy/models';
import { IAdditional, IResponse } from '@onesy/sdk/other';
import OnesySubscription from '@onesy/subscription';

import { BaseService } from './base';
import { MainService, AuthService } from 'services';
import { IQuerySubscription } from 'types';

class WebsiteService extends BaseService<Website> {
  public queryWebsitePages = new OnesySubscription<IQuerySubscription>();

  public constructor() {
    super('websites');

    this.queryObjectsInit(this.queryWebsitePages, 'queryPagePost');
  }

  // page
  public async addPage(id: string, body: Page, options?: IAdditional): Promise<IResponse> {
    const response = await MainService.sdk.websites.addPage(id, body as any, options);

    AuthService.me();

    return response;
  }

  public async getPage(id: string, page_id: string, options?: IAdditional): Promise<IResponse> {
    return MainService.sdk.websites.getPage(id, page_id, options);
  }

  public async queryPage(id: string, options?: IAdditional): Promise<IResponse> {
    return MainService.sdk.websites.queryPage(id, options);
  }

  public async queryPagePost(id: string, body?: Partial<Query>, options?: IAdditional): Promise<IResponse> {
    return MainService.sdk.websites.queryPagePost(id, body, options);
  }

}

export default new WebsiteService();
