import React from 'react';
import { Outlet } from 'react-router';

import { Line } from '@onesy/ui-react';
import { classNames, style } from '@onesy/style-react';

const useStyle = style(theme => ({
  root: {
    padding: '40px 24px',
    backgroundImage: `url('/assets/images/background.jpg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    overflow: 'hidden auto',

    '& .onesy-SectionReviews-review': {
      boxShadow: theme.shadows.values.default[1]
    }
  },

  header: {

  },

  logo: {
    height: 44,
    width: 'auto',
    cursor: 'pointer',
    userSelect: 'none',
    transition: theme.methods.transitions.make('transform', { duration: 'xs' }),

    '&:active': {
      transform: 'scale(0.94)'
    }
  },

  main: {
    maxWidth: 440,
    backdropFilter: 'blur(4px)',
    background: theme.methods.palette.color.colorToRgb(theme.palette.background.primary.secondary, theme.palette.light ? 94 : 84),
    padding: 40,
    borderRadius: 4,
    overflow: 'hidden'
  }
}), { name: 'onesy-route-Root-Auth' });

const Root = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  if (!loaded) return null;

  return (
    <Line
      ref={ref}

      align='center'

      justify='center'

      flex

      fullWidth

      className={classNames([
        className,
        classes.root,
        'onesy-Root-Auth'
      ])}

      {...other}
    >
      <Line
        gap={0}

        direction='column'

        fullWidth

        className={classes.main}
      >
        <Outlet />
      </Line>
    </Line>
  );
});

export default Root;
