import React from 'react';

import { stringToColor, textToInnerHTML } from '@onesy/utils';
import { Line, ListItem, Medias, Properties, RoundProgress, Type, useSnackbars } from '@onesy/ui-react';
import { classNames, style, useOnesyTheme } from '@onesy/style-react';
import { IBaseElement } from '@onesy/ui-react/types';
import { INutritionPlan } from '@onesy/api';

import IconMaterialMenuBook from '@onesy/icons-material-rounded-react/IconMaterialMenuBookW100';

import { Divider, Input, Inputs, ModalForm, NoResults, View } from 'ui';
import { formatNumber, getErrorMessage, propertyCalories } from 'utils';
import Recipe from './RecipeRead';
import { AppService, NutritionPlanService } from 'services';

const useStyle = style(theme => ({
  root: {

  },

  rootNotLoaded: {
    minHeight: '40vh'
  },

  week: {
    flex: '0 0 auto',
    padding: 1,
    overflow: 'auto hidden'
  },

  day: {
    border: `1px solid ${theme.palette.light ? '#dadada' : '#575757'}`,
    height: 'unset',
    marginLeft: -1,
    marginTop: -1,
    minHeight: 300,
    minWidth: 340,
    padding: 12
  },

  workouts: {
    maxHeight: 145,
    overflow: 'hidden auto'
  },

  rest: {
    opacity: 0.4,
    cursor: 'default',
    pointerEvents: 'none'
  }
}), { name: 'onesy-NutritionPlan' });

export interface INutritionPlanElement extends IBaseElement {
  object: INutritionPlan;
}

const Element: React.FC<INutritionPlanElement> = React.forwardRef((props, ref: any) => {
  const {
    object: objectProps,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const theme = useOnesyTheme();
  const snackbars = useSnackbars();

  const [object, setObject] = React.useState<INutritionPlan>();
  const [openRecipe, setOpenRecipe] = React.useState<any>({ open: false });
  const [loaded, setLoaded] = React.useState(false);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const init = React.useCallback(async () => {
    const result = await NutritionPlanService.get(objectProps?.id);

    if (result.status >= 400) {
      snackbars.add({
        primary: getErrorMessage(result),
        color: 'error'
      });
    }
    else {
      setObject(result.response.response);
    }

    setLoaded(true);
  }, [objectProps]);

  React.useEffect(() => {
    if (!object || (objectProps && objectProps.id !== object.id)) init();
  }, [objectProps?.id]);

  const media = [object?.video, object?.image, object?.audio].filter(Boolean);

  const onOpenRecipe = React.useCallback((item: any) => {
    setOpenRecipe((previous: any) => ({
      ...previous,

      recipe: item,

      open: true
    }));
  }, []);

  const onCloseRecipe = React.useCallback(() => {
    setOpenRecipe((previous: any) => ({
      ...previous,

      open: false
    }));
  }, []);

  const getTotalDay = React.useCallback((index: number, indexDay: number) => {
    const weeks = object.weeks;

    const ingredients = weeks[index].days[indexDay].ingredients || [];

    let value = 0;

    ingredients.forEach(item => {
      const quantityID = `${weeks[index].id}-${weeks[index].days[indexDay].id}-${item.id}`;

      value += +((item.nutrition[propertyCalories] / 100) * (object.quantities?.[quantityID] || 0)).toFixed(2);
    });

    return +(value).toFixed(2);
  }, [object]);

  const getTotalWeek = React.useCallback((index: number) => {
    const weeks = object.weeks;

    const week = weeks[index];

    let value = 0;

    week.days.forEach((day, indexDay) => {
      value += getTotalDay(index, indexDay);
    });

    return +(value).toFixed(2);
  }, [object]);

  const getTotal = React.useCallback(() => {
    const weeks = object.weeks;

    let value = 0;

    weeks.forEach((week, index) => {
      value += getTotalWeek(index);
    });

    return +(value).toFixed(2);
  }, [object]);

  const propertiesProps: any = {
    rowGap: 0.5,
    version: 'row',
    size: 'large'
  };

  return <>
    <ModalForm
      name={object?.name}

      object={object}

      onClose={onClose}

      add
    >
      <Line
        ref={ref}

        gap={4}

        align={loaded ? 'flex-start' : 'center'}

        justify={loaded ? 'flex-start' : 'center'}

        flex

        fullWidth

        className={classNames([
          'onesy-NutritionPlan',

          className,
          classes.root,
          !loaded && classes.rootNotLoaded
        ])}

        {...other}
      >
        {!loaded && <RoundProgress />}

        {loaded && !object && <NoResults />}

        {loaded && object && <>
          <Line
            gap={2.5}

            fullWidth
          >
            {!!media?.length && (
              <Medias
                align='center'

                values={media.map((item: any) => ({
                  value: (item?.id || item?.url || item?.urlEmbed) ? item : undefined
                }))}

                size='large'
              />
            )}

            <Properties
              values={[
                ...(object.total_calories !== undefined ? [
                  {
                    name: 'Total calories',
                    value: `${formatNumber(object.total_calories)} kcal`
                  }
                ] : []),

                ...(object.average_calories_month !== undefined ? [
                  {
                    name: 'Average calories per month',
                    value: `${formatNumber(object.average_calories_month)} kcal`
                  }
                ] : []),

                ...(object.average_calories_week !== undefined ? [
                  {
                    name: 'Average calories per week',
                    value: `${formatNumber(object.average_calories_week)} kcal`
                  }
                ] : []),

                {
                  name: 'Repeat',
                  value: `${object.repeat} times`
                }
              ]}

              {...propertiesProps}
            />

            {object.description && (
              <Type
                version='b1'

                whiteSpace='pre-wrap'

                dangerouslySetInnerHTML={{
                  __html: textToInnerHTML(object.description)
                }}
              />
            )}
          </Line>

          <Inputs
            gap={3}

            gapMain={2.5}

            name='Weeks'

            description={(
              <Type
                version='t1'

                weight={400}
              >
                Total calories <b>{getTotal()}</b> kcal
              </Type>
            )}

            size='large'
          >
            {object.weeks?.map((week, index) => (
              <Input
                key={week.id}

                gap={2}

                name={`Week ${index + 1}`}

                description={(
                  <Type
                    version='b1'
                  >
                    Total weekly calories <b>{getTotalWeek(index)}</b> kcal
                  </Type>
                )}

                size='large'
              >
                {week.rest && (
                  <NoResults
                    padding={0}

                    maxWidth={340}
                  >
                    Rest week
                  </NoResults>
                )}

                {!week.rest && (
                  <Line
                    gap={0}

                    direction='row'

                    align='stretch'

                    fullWidth

                    className={classes.week}
                  >
                    {week.days?.map((day, indexDay) => {
                      const withValues = !!day.ingredients?.length;

                      return (
                        <Input
                          name={`Day ${indexDay + 1}`}

                          NameProps={{
                            align: 'flex-start'
                          }}

                          className={classNames([
                            classes.day
                          ])}

                          size='small'
                        >
                          <Line
                            gap={3}

                            fullWidth
                          >
                            {!day.rest ? <>
                              {!!day.ingredients?.length && (
                                <Input
                                  name='Ingredients'

                                  weight={600}

                                  size='small'
                                >
                                  <Line
                                    gap={2}

                                    fullWidth
                                  >
                                    {withValues && <>
                                      {day.ingredients?.map((item, indexIngredient) => {
                                        const quantityID = `${week.id}-${day.id}-${item.id}`;

                                        return (
                                          <ListItem
                                            key={item.id}

                                            color='inherit'

                                            primary={(
                                              <Type
                                                version='t2'

                                                weight={400}
                                              >
                                                {item.name}
                                              </Type>
                                            )}

                                            secondary={(
                                              <Line
                                                gap={1}

                                                direction='row'

                                                wrap='wrap'

                                                align='center'
                                              >
                                                <Type
                                                  version='b1'

                                                  weight={300}

                                                  whiteSpace='nowrap'
                                                >
                                                  {item.nutrition[propertyCalories] / 100}
                                                </Type>

                                                <Type
                                                  version='b1'

                                                  weight={100}

                                                  whiteSpace='nowrap'
                                                >
                                                  kcal / g x
                                                </Type>

                                                <Type
                                                  version='b1'

                                                  weight={100}

                                                  style={{
                                                    width: 'auto'
                                                  }}
                                                >
                                                  {object.quantities?.[quantityID]}
                                                </Type>

                                                <Type
                                                  version='b1'

                                                  weight={100}

                                                  whiteSpace='nowrap'
                                                >
                                                  g =
                                                </Type>

                                                <Type
                                                  version='b1'

                                                  weight={300}

                                                  whiteSpace='nowrap'
                                                >
                                                  {+((item.nutrition[propertyCalories] / 100) * (object.quantities?.[quantityID] || 0)).toFixed(2)} kcal
                                                </Type>
                                              </Line>
                                            )}

                                            size='small'

                                            Component='div'

                                            noBackground

                                            noPadding
                                          />
                                        );
                                      })}

                                      <Divider size='small' />

                                      <Input
                                        name='Total calories'

                                        endName={(
                                          <Type
                                            version='t2'

                                            weight={500}
                                          >
                                            {getTotalDay(index, indexDay)} kcal
                                          </Type>
                                        )}

                                        size='small'
                                      />
                                    </>}
                                  </Line>
                                </Input>
                              )}

                              {!!day.recipes?.length && (
                                <Input
                                  name='Recipes'

                                  weight={600}

                                  size='small'
                                >
                                  {!!day.recipes?.length && (
                                    <Line
                                      gap={1}

                                      fullWidth

                                      className={classes.recipes}
                                    >
                                      {day.recipes.map(recipe => {
                                        const palette = theme.methods.color(stringToColor(recipe?.name || 'recipe'));

                                        return (
                                          <ListItem
                                            start={<IconMaterialMenuBook />}

                                            primary={(
                                              <Type
                                                version='t2'

                                                color='default'
                                              >
                                                {recipe.name}
                                              </Type>
                                            )}

                                            onClick={() => onOpenRecipe(recipe)}

                                            size='small'

                                            Component='div'

                                            button

                                            noBackground

                                            style={{
                                              background: palette[theme.palette.light ? 90 : 20]
                                            }}
                                          />
                                        );
                                      })}
                                    </Line>
                                  )}
                                </Input>
                              )}
                            </> : <>
                              <Line
                                align='center'

                                fullWidth
                              >
                                <NoResults
                                  padding={0}

                                  maxWidth={240}

                                  size='small'
                                >
                                  Rest day
                                </NoResults>
                              </Line>
                            </>}
                          </Line>
                        </Input>
                      );
                    })}
                  </Line>
                )}
              </Input>
            ))}
          </Inputs>
        </>}
      </Line>
    </ModalForm>

    <View
      open={openRecipe.open}

      onClose={onCloseRecipe}

      minWidth='xl'
    >
      <ModalForm
        name={openRecipe.recipe?.name}

        object={object}

        onClose={onCloseRecipe}

        add
      >
        <Recipe
          object={openRecipe.recipe}
        />
      </ModalForm>
    </View>
  </>;
});

export default Element;
