import React from 'react';

import { capitalize, cleanValue, textToInnerHTML } from '@onesy/utils';
import { Line, Medias, Properties, RoundProgress, Type, useSnackbars } from '@onesy/ui-react';
import { classNames, style } from '@onesy/style-react';
import { IBaseElement } from '@onesy/ui-react/types';
import { IRecipe } from '@onesy/api';

import { Input, NoResults } from 'ui';
import { RecipeService } from 'services';
import { getErrorMessage } from 'utils';

const useStyle = style(theme => ({
  root: {

  },

  rootNotLoaded: {
    minHeight: '40vh'
  },

  item: {
    padding: 16
  }
}), { name: 'onesy-Recipe' });

export interface IRecipeElement extends IBaseElement {
  object: IRecipe;
}

const Element: React.FC<IRecipeElement> = React.forwardRef((props, ref: any) => {
  const {
    object: objectProps,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState<IRecipe>();
  const [loaded, setLoaded] = React.useState(false);

  const init = React.useCallback(async () => {
    const result = await RecipeService.get(objectProps?.id);

    if (result.status >= 400) {
      snackbars.add({
        primary: getErrorMessage(result),
        color: 'error'
      });
    }
    else {
      setObject(result.response.response);
    }

    setLoaded(true);
  }, [objectProps]);

  React.useEffect(() => {
    if (!object || (objectProps && objectProps.id !== object.id)) init();
  }, [objectProps?.id]);

  const media = [object?.video, object?.image, object?.audio].filter(Boolean);

  const getName = React.useCallback(item => {
    if (item?.toLowerCase() === 'default') return 'Main';

    return cleanValue(item, { capitalize: true });
  }, []);

  const ingredients = Object.keys(object?.ingredients || {});

  const instructions = Object.keys(object?.instructions || {});

  const additional = Object.keys(object?.additional || {});

  const getItems = (name: string, items: any) => {
    return (
      <Line
        gap={0.5}

        Component={name === 'instructions' ? 'ol' : 'ul'}

        style={{
          listStylePosition: 'inside'
        }}
      >
        {items.map(item => item?.trim().replace(/^[0-9]+./, '').replace(/^-+./, '').replace(/^\++./, '').replace(/^\*+./, '').trim()).filter(Boolean).map((item, index) => (
          <Type
            key={index}

            version='b1'

            whiteSpace='pre-wrap'

            Component='li'

            dangerouslySetInnerHTML={{
              __html: textToInnerHTML(capitalize(item))
            }}
          />
        ))}
      </Line>
    );
  };

  const getGroup = (name: string, group: any, items: any) => {

    return <>
      {!!items.length && (
        <Input
          gap={1.5}

          name={capitalize(textToInnerHTML(name))}

          weight={500}

          size='large'
        >
          {items.length === 1 ? getItems(name, group[items[0]]) : <>
            {items.map(type => (
              <Input
                name={getName(textToInnerHTML(type))}

                weight={400}
              >
                {getItems(name, group[type])}
              </Input>
            ))}
          </>}
        </Input>
      )}
    </>
  };

  const propertiesProps: any = {
    rowGap: 0.5,
    version: 'row',
    size: 'large'
  };

  return (
    <Line
      ref={ref}

      gap={4}

      align={loaded ? 'flex-start' : 'center'}

      justify={loaded ? 'flex-start' : 'center'}

      flex

      fullWidth

      className={classNames([
        'onesy-Recipe',

        className,
        classes.root,
        !loaded && classes.rootNotLoaded
      ])}

      {...other}
    >
      {!loaded && <RoundProgress />}

      {loaded && !object && <NoResults />}

      {loaded && object && <>
        <Line
          gap={4}

          fullWidth
        >
          <Line
            gap={2.5}

            fullWidth
          >
            {!!media?.length && (
              <Medias
                align='center'

                values={media.map((item: any) => ({
                  value: (item?.id || item?.url || item?.urlEmbed) ? item : undefined
                }))}

                size='large'
              />
            )}

            <Properties
              values={[
                {
                  name: 'Prepare time',
                  value: capitalize(object?.time)
                },
                {
                  name: 'Difficulty',
                  value: capitalize(object?.difficulty)
                },
                {
                  name: 'Serving',
                  value: capitalize(object?.serving)
                }
              ]}

              {...propertiesProps}
            />

            {object.description && (
              <Type
                version='b1'

                whiteSpace='pre-wrap'

                dangerouslySetInnerHTML={{
                  __html: textToInnerHTML(object.description)
                }}
              />
            )}
          </Line>

          <Line
            gap={3}

            fullWidth
          >
            {getGroup('ingredients', object?.ingredients, ingredients)}

            {getGroup('instructions', object?.instructions, instructions)}

            {getGroup('additional', object?.additional, additional)}
          </Line>
        </Line>
      </>}
    </Line>
  );
});

export default Element;
