import { Invoice } from '@onesy/api';
import { IResponse } from '@onesy/sdk/other';

import { BaseService } from './base';
import { MainService } from 'services';

class InvoiceService extends BaseService<Invoice> {

  get paginationLimit() { return 20; }

  public constructor() {
    super('invoices');
  }

  public async email(id: string): Promise<IResponse> {
    return MainService.sdk.invoices.email(id);
  }

}

export default new InvoiceService();
