import OnesySub from '@onesy/subscription';
import OnesyRequest from '@onesy/request';

import { IAdd } from 'ui/layout/Add';
import { AuthService } from 'services';

class AppService {
  public pages = {
    add: new OnesySub<IAdd>(),
    addSecondary: new OnesySub<IAdd>(),
    addTertiary: new OnesySub<IAdd>()
  };

  public init() {
    OnesyRequest.interceptors.response.error.subscribe(async (value: any) => {
      // Authentication error
      if (value.status === 401) await AuthService.signOut(false);
    });
  }

  public constructor() {
    this.init();
  }

}

export default new AppService();
