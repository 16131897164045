import { Goal } from '@onesy/api';

import { BaseService } from './base';

class GoalService extends BaseService<Goal> {

  get paginationLimit() {
    return 'all';
  }

  public constructor() {
    super('goals');
  }

}

export default new GoalService();
