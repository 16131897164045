import { Transaction } from '@onesy/api';

import { BaseService } from './base';

class TransactionService extends BaseService<Transaction> {

  get paginationLimit() { return 30; }

  get sort() { return { made_at: -1 }; }

  public constructor() {
    super('transactions');
  }

}

export default new TransactionService();
