import { Recipe } from '@onesy/api';

import { BaseService } from './base';

class RecipeService extends BaseService<Recipe> {

  get paginationLimit() {
    return 30;
  }

  public constructor() {
    super('recipes');
  }

}

export default new RecipeService();
