import React from 'react';

import { List } from '@onesy/ui-react';
import { IList } from '@onesy/ui-react/List/List';
import { classNames, style } from '@onesy/style-react';

const useStyle = style(theme => ({
  root: {
    '&.onesy-Surface-root': {
      background: 'transparent'
    }
  }
}), { name: 'onesy-List' });

const Element: React.FC<IList> = React.forwardRef((props: IList, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  return (
    <List
      ref={ref}

      tonal

      color='inherit'

      className={classNames([
        classes.root,
        className
      ])}

      {...other}
    />
  );
});

Element.displayName = 'onesy-List';

export default Element;
