import React from 'react';

import { cleanValue } from '@onesy/utils';
import { ListItem, Type, useSubscription } from '@onesy/ui-react';
import { ISelect } from '@onesy/ui-react/Select/Select';

import { AuthService } from 'services';
import { ISignedIn } from 'types';
import Select from './Select';

const Element: React.FC<ISelect> = React.forwardRef((props: ISelect, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const options = React.useMemo(() => {
    const values: any = [];

    if (signedIn?.user?.is?.onesy) values.push({
      name: 'onesy',
      description: 'Platform admin',
      value: 'onesy'
    });

    values.push(
      { name: 'Admin', description: 'Organization admin, has access to most of the features, settings', value: 'admin' },
      { name: 'User', description: 'Organization user, has access to all of the apps', value: 'user' }
    );

    return values;
  }, [signedIn]);

  return (
    <Select
      ref={ref}

      name='Roles'

      renderValues={(value: any) => value?.map((item: any) => cleanValue(item, { capitalize: true })).join(', ')}

      multiple

      {...other}
    >
      {options.map((item: any, index: number) => (
        <ListItem
          key={index}

          primary={(
            <Type
              version='l1'

              weight={500}
            >
              {item.name}
            </Type>
          )}

          secondary={(
            <Type
              version='b2'
            >
              {item.description}
            </Type>
          )}

          value={item.value}

          button
        />
      ))}
    </Select>
  );
});

Element.displayName = 'onesy-SelectRole';

export default Element;
