import OnesyRequest from '@onesy/request';
import { OnesyRequestResponse, IOptions } from '@onesy/request/OnesyRequest';

import config from 'config';

export class RequestService {
  public onesyRequest: OnesyRequest;

  public constructor() {
    this.onesyRequest = new OnesyRequest({
      request: {
        // urlStart: config.value.apps.api.url
      },

      response: {
        resolveOnError: true
      }
    });

    // interceptors
    this.onesyRequest.interceptors.request.pre.subscribe((value: any) => {
      // Add onesy token
      // to authorization
      if (config.value.onesy_token) value.request.headers['Authorization'] = `onesy ${config.value.onesy_token}`;
    });
  }

  public get(url: string, options?: IOptions): Promise<OnesyRequestResponse> {
    return this.onesyRequest.get(url, options);
  }

  public post(url: string, body: any, options?: IOptions): Promise<OnesyRequestResponse> {
    return this.onesyRequest.post(url, body, options);
  }

  public put(url: string, body: any, options?: IOptions): Promise<OnesyRequestResponse> {
    return this.onesyRequest.put(url, body, options);
  }

  public delete(url: string, options?: IOptions): Promise<OnesyRequestResponse> {
    return this.onesyRequest.delete(url, options);
  }

}

export default new RequestService();
