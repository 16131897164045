import { Room } from '@onesy/api';

import { BaseService } from './base';

class RoomService extends BaseService<Room> {

  get paginationLimit() {
    return 'all';
  }

  public constructor() {
    super('rooms');
  }

}

export default new RoomService();
