import { SignIn } from '@onesy/api';
import { IAdditional } from '@onesy/sdk/other';

import { BaseService } from './base';
import { MainService, AuthService } from 'services';

class SignInService extends BaseService<SignIn> {

  public constructor() {
    super('signIns');
  }

  public async inactivateAll(options?: IAdditional) {
    return MainService.sdk[this.route as 'signIns']?.inactivateAll(options);
  }

  public async removeAll(options?: IAdditional) {
    const response = await MainService.sdk[this.route as 'signIns']?.removeAll(options);

    AuthService.me();

    return response;
  }

}

export default new SignInService();
