import React from 'react';

import { Info } from '@onesy/ui-react';
import { IInfo } from '@onesy/ui-react/Info/Info';

const Element: React.FC<IInfo> = React.forwardRef((props, ref: any) => {
  return (
    <Info
      ref={ref}

      IconProps={{
        size: 'large'
      }}

      {...props}
    />
  );
});

export default Element;
