import { Location } from '@onesy/api';

import { BaseService } from './base';

class LocationService extends BaseService<Location> {

  get paginationLimit() {
    return 'all';
  }

  public constructor() {
    super('locations');
  }

}

export default new LocationService();
