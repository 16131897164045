import React from 'react';

import { textToInnerHTML } from '@onesy/utils';
import { Slide } from '@onesy/ui-react';
import { IconButton, Line, Modal, ModalHeader, ModalTitle, Tooltip } from '@onesy/ui-react';
import { IModal } from '@onesy/ui-react/Modal/Modal';
import { classNames, style } from '@onesy/style-react';

import IconMaterialKeyboardArrowDown from '@onesy/icons-material-rounded-react/IconMaterialKeyboardArrowDownW100';

const useStyle = style(theme => ({
  root: {
    '&.onesy-Surface-root': {
      background: 'transparent'
    }
  },

  root_modal: {

  },

  header: {
    position: 'relative',
    padding: '12px 12px 12px 24px',
    minHeight: 40,

    '&.onesy-ModalHeader-root': {
      padding: 0
    }
  },

  headerName: {
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },

  padding: {
    padding: 24
  },

  paddingSmall: {
    padding: 12
  },

  surface: {
    '&.onesy-Surface-root': {
      padding: 0,
      background: theme.palette.color.primary[theme.palette.light ? 99 : 5]
    }
  },

  maxHeight: {
    height: 'calc(100% - 80px)'
  }
}), { name: 'onesy-View' });

export interface IView extends IModal {
  name?: any;

  maxHeight?: boolean;

  minWidth?: 'xss' | 'xs' | 'sm' | 'rg' | 'lg' | 'xl' | 'xxl';

  helpers?: boolean;

  [p: string]: any;
}

const Element = React.forwardRef((props: IView, ref: any) => {
  const {
    name,

    maxHeight,

    minWidth = 'lg',

    onClose,

    helpers = !props.children,

    className,

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  // modal
  return (
    <Modal
      ref={ref}

      minWidth={minWidth}

      onClose={onClose}

      TransitionComponent={Slide}

      SurfaceProps={{
        tonal: true,
        color: 'primary',

        className: classNames([
          classes.surface,
          maxHeight && classes.maxHeight
        ])
      }}

      className={classNames([
        className,
        classes.root_modal
      ])}

      {...other}
    >
      {helpers && (
        <ModalHeader
          gap={0}
        >
          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth

            className={classNames([
              classes.header
            ])}
          >
            <ModalTitle
              className={classes.headerName}

              dangerouslySetInnerHTML={{
                __html: textToInnerHTML(name)
              }}
            />

            <Line
              gap={0}

              align='center'

              justify='center'

              direction='row'
            >
              <Tooltip
                name='Close'
              >
                <IconButton
                  color='inherit'

                  onClick={onClose}
                >
                  <IconMaterialKeyboardArrowDown
                    size='large'
                  />
                </IconButton>
              </Tooltip>
            </Line>
          </Line>
        </ModalHeader>
      )}

      {children}
    </Modal>
  );
});

export default Element;
