import { CustomerGroup } from '@onesy/api';

import { BaseService } from './base';

class CustomerGroupService extends BaseService<CustomerGroup> {

  get paginationLimit() {
    return 'all';
  }

  public constructor() {
    super('customerGroups');
  }

}

export default new CustomerGroupService();
