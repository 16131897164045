import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Form, FormRow, Line, Type, useForm, useQuery, useSnackbars, useSubscription } from '@onesy/ui-react';
import { classNames, style } from '@onesy/style-react';
import { ISignIn } from '@onesy/sdk/other';

import { Button, TextField } from 'ui';
import { AuthService, UserService } from 'services';
import { getDeviceAndLocation, getErrorMessage, getRootPage } from 'utils';
import { ISignedIn } from 'types';

const useStyle = style(theme => ({
  root: {},

  buttons: {
    marginTop: 30
  },

  buttonGoogle: {
    backgroundColor: theme.palette.light ? theme.palette.color.primary[100] : theme.palette.color.primary[10],
    transition: `${[theme.methods.transitions.make(['color', 'box-shadow']), theme.methods.transitions.make('transform', { duration: 'rg' })].join(', ')} !important`,

    '&:active': {
      transform: 'scale(0.97)'
    }
  },

  iconGoogle: {
    width: 'auto',
    height: 18,
    marginRight: 4,
    flex: '0 0 auto'
  }
}), { name: 'onesy-route-SignIn' });

// Todo
// add MFA as a step if required
const SignIn = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const query = useQuery();
  const navigate = useNavigate();
  const snackbars = useSnackbars();
  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [loading, setLoading] = React.useState(false);

  const form = useForm({
    values: {
      'email': {
        name: 'email',
        isValid: 'email',
        required: true
      },
      'password': {
        name: 'password',
        required: true
      }
    }
  });

  const refs = {
    onesy: React.useRef<any>(undefined),
    form: React.useRef(form)
  };

  refs.form.current = form;

  const signInUser = React.useCallback(async (value: string) => {
    await AuthService.init(value);

    // redirect to
    const redirect_to = query.get('redirect_to');

    if (redirect_to) navigate(redirect_to);
    else {
      // or to home
      navigate(getRootPage(signedIn));
    }
  }, [query]);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await form.validate();

    if (!valid) return;

    setLoading(true);

    const body: ISignIn = {
      ...(await getDeviceAndLocation()),

      ...refs.form.current.value
    };

    const result = await AuthService.signIn(body, { query: { customer: true } });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const response = result.response.response;

      await signInUser(response);

      snackbars.add({
        primary: 'Signed in succefully!'
      });
    }

    setLoading(false);
  }, [form]);

  return (
    <Line
      ref={ref}

      gap={4}

      direction='column'

      align='center'

      fullWidth

      className={classNames([
        className,
        classes.root,
        'onesy-SignIn'
      ])}

      {...other}
    >
      <Type
        version='h3'
      >
        Sign in
      </Type>

      <Form
        onSubmit={onSubmit}
      >
        <FormRow
          fullWidth
        >
          <TextField
            name='Email'

            type='email'

            valueDefault={form.values['email'].value}

            error={form.values['email'].error}

            helperText={form.values['email'].error}

            onChange={(valueNew: any) => form.onChange('email', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth

            disabled={!!UserService.invite}
          />

          <TextField
            name='Password'

            type='password'

            valueDefault={form.values['password'].value}

            error={form.values['password'].error}

            helperText={form.values['password'].error}

            onChange={(valueNew: any) => form.onChange('password', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />
        </FormRow>

        <Line
          direction='row'

          align='center'

          justify='space-between'

          fullWidth

          className={classes.buttons}
        >
          <div />

          <Button
            type='submit'

            // onClick={() => setStep(2)}

            disabled={loading}
          >
            Next
          </Button>
        </Line>
      </Form>
    </Line>
  );
});

export default SignIn;
