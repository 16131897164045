import { Earning } from '@onesy/api';

import { BaseService } from './base';

class EarningService extends BaseService<Earning> {

  get paginationLimit() {
    return 'all';
  }

  public constructor() {
    super('earnings');
  }

}

export default new EarningService();
