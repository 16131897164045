import { Media } from '@onesy/api';
import OnesySubscription from '@onesy/subscription';

import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'types';

class MediaService extends BaseService<Media> {
  public queryObjectsModal = new OnesySubscription<IQuerySubscription>();
  public selectObjectModal = new OnesySubscription<ISelectedSubscription>();

  public constructor() {
    super('medias');

    this.queryObjectsInit(this.queryObjectsModal);

    this.selectedObjectsInit(this.selectObjectModal);
  }

}

export default new MediaService();
