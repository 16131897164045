import { Review } from '@onesy/api';

import { BaseService } from './base';

class ReviewService extends BaseService<Review> {

  get paginationLimit() {
    return 'all';
  }

  public constructor() {
    super('reviews');
  }

}

export default new ReviewService();
