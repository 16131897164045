import { WorkoutPlan } from '@onesy/api';

import { BaseService } from './base';

class WorkoutPlanService extends BaseService<WorkoutPlan> {

  get paginationLimit() {
    return 'all';
  }

  public constructor() {
    super('workoutPlans');
  }

}

export default new WorkoutPlanService();
