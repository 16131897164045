import React from 'react';

import { Button } from '@onesy/ui-react';
import { IButton } from '@onesy/ui-react/Button/Button';
import { classNames, style } from '@onesy/style-react';

const useStyle = style(theme => ({
  root: {
    '&.onesy-Button-size-regular': {
      paddingBlock: 8
    },

    '&.onesy-Button-size-large': {
      paddingBlock: 10
    },

    '& .onesy-Type-root': {
      whiteSpace: 'nowrap'
    }
  }
}), { name: 'onesy-Button' });

const Element: React.FC<IButton> = React.forwardRef((props, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  return (
    <Button
      ref={ref}

      tonal

      color={other.version === 'text' ? 'inherit' : 'primary'}

      version='filled'

      size='large'

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    />
  );
});

Element.displayName = 'onesy-Button';

export default Element;
