import React from 'react';

import { Snackbars } from '@onesy/ui-react';
import { ISnackbars } from '@onesy/ui-react/Snackbars/Snackbars';
import { classNames, style } from '@onesy/style-react';

const useStyle = style(theme => ({
  root: {

  }
}), { name: 'onesy-Snackbars' });

const Element = React.forwardRef((props: ISnackbars, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  return (
    <Snackbars
      ref={ref}

      position='bottom'

      alignment='end'

      className={classNames([
        classes.root,
        className
      ])}

      {...other}
    />
  );
});

Element.displayName = 'onesy-Snackbars';

export default Element;
