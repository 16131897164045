import React from 'react';

import { DateRangePicker } from '@onesy/ui-react';
import { IDateRangePicker } from '@onesy/ui-react/DateRangePicker/DateRangePicker';

const Element: React.FC<IDateRangePicker> = React.forwardRef((props, ref: any) => {
  return (
    <DateRangePicker
      ref={ref}

      size='small'

      IconProps={{
        size: 'regular'
      }}

      {...props}
    />
  );
});

export default Element;
