import OnesySub from '@onesy/subscription';
import { IAdditional, IPasswordForgot, IPasswordForgotOTP, IPasswordForgotUpdate, IResponse, ISignIn, ISignInGoogle, ISignInMFAApp, ISignUp, ISignUpGoogle, ISignUpOTP, ISignUpOTPResend, ISwitch } from '@onesy/sdk/other';

import { BaseService } from './base';
import { MainService, UserService, StorageService, SocketService } from 'services';
import { ISignedIn } from 'types';
import config from 'config';

class AuthService extends BaseService {
  public signedIn = new OnesySub<ISignedIn>();
  public redirect = true;

  public constructor() {
    super('auth');
  }

  // init
  // add token to the storage
  // init the sdk with it
  // use for user me
  // emit the user
  public async init(value: string = StorageService.get('token'), reset = true) {
    if (!value) return;

    // reset
    if (value && reset) {
      // add token to the storage
      StorageService.add('token', value);

      config.values.onesy_token = value;

      // init the sdk
      MainService.sdk.init({ onesy_token: value });

      // Sockets
      SocketService.init();

      UserService.invite = undefined as any;
    }

    // user me
    const user = await UserService.me();

    if (user.status >= 400) {
      this.clear();

      this.signedIn.emit(null as any);
    }
    else {
      // emit the user
      this.signedIn.emit(user.response.response);
    }

    // Todo
    // Reload all the information
    // ie. tasks, notes, etc.
    if (reset) {

    }
  }

  public async me() {
    // user me
    const user = await UserService.me();

    if (user.status >= 400) {
      this.clear();

      this.signedIn.emit(null as any);
    }
    else {
      // emit the user
      this.signedIn.emit(user.response.response);
    }
  }

  public get isAuthenticated() {
    return this.signedIn.value && this.signedIn.value?.user?.id && this.signedIn.value?.organization.id;
  }

  public async signIn(body: ISignIn, options?: IAdditional) {
    return MainService.sdk.auth.signIn(body, options);
  }

  public async signInGoogle(body: ISignInGoogle, options?: IAdditional) {
    return MainService.sdk.auth.signInGoogle(body, options);
  }

  public async signUp(body: ISignUp, options?: IAdditional) {
    return MainService.sdk.auth.signUp(body, options);
  }

  public async signUpOTP(body: ISignUpOTP, options?: IAdditional) {
    return MainService.sdk.auth.signUpOTP(body, options);
  }

  public async signUpOTPResend(body: ISignUpOTPResend, options?: IAdditional) {
    return MainService.sdk.auth.signUpOTPResend(body, options);
  }

  public async signUpGoogle(body: ISignUpGoogle, options?: IAdditional) {
    return MainService.sdk.auth.signUpGoogle(body, options);
  }

  public async signInMFAapp(body: ISignInMFAApp, options?: IAdditional) {
    return MainService.sdk.auth.signInMFAapp(body, options);
  }

  public async forgotPassword(body: IPasswordForgot, options?: IAdditional) {
    return MainService.sdk.auth.forgotPassword(body, options);
  }

  public async forgotPasswordOTP(body: IPasswordForgotOTP, options?: IAdditional) {
    return MainService.sdk.auth.forgotPasswordOTP(body, options);
  }

  public async forgotPasswordUpdate(body: IPasswordForgotUpdate, options?: IAdditional) {
    return MainService.sdk.auth.forgotPasswordUpdate(body, options);
  }

  public async switch(body: ISwitch, options?: IAdditional): Promise<IResponse> {
    return MainService.sdk.auth.switch(body, options);
  }

  public async refresh(options?: IAdditional) {
    return MainService.sdk.auth.refresh(options);
  }

  public async signOut(redirect = true) {
    // clear storage
    StorageService.clear();

    // clear sdk
    MainService.sdk.clear();

    this.redirect = redirect;

    // signedIn
    this.signedIn.emit(null as any);
  }

  public clear() {
    // clear storage
    StorageService.clear();

    // clear sdk
    MainService.sdk.clear();
  }

}

export default new AuthService();
