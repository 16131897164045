import React from 'react';

import { cleanValue, textToInnerHTML } from '@onesy/utils';
import { Type, useSubscription } from '@onesy/ui-react';

import { Input, Inputs, ModalForm } from 'ui';
import { AppService, AuthService } from 'services';
import { getDate } from 'utils';
import { ISignedIn } from 'types';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object
  } = props;

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const customer = signedIn.customer;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const inputProps: any = {
    gap: 0.5,
    size: 'small'
  };

  const modal: any = {
    read: (
      <Inputs
        gapMain={2}
      >
        {object?.response?.date && (
          <Input
            name='Date'

            {...inputProps}
          >
            <Type
              version='b1'
            >
              {getDate(object.response.date)}
            </Type>
          </Input>
        )}

        {object?.response?.height !== undefined && (
          <Input
            name='Height'

            {...inputProps}
          >
            <Type
              version='b1'
            >
              {object.response.height}
            </Type>
          </Input>
        )}

        {object?.response?.weight !== undefined && (
          <Input
            name='Weight'

            {...inputProps}
          >
            <Type
              version='b1'
            >
              {object.response.weight}
            </Type>
          </Input>
        )}

        {object?.response?.BMI !== undefined && (
          <Input
            name='BMI'

            {...inputProps}
          >
            <Type
              version='b1'
            >
              {object.response.BMI}
            </Type>
          </Input>
        )}

        {object?.response?.age !== undefined && (
          <Input
            name='Age'

            {...inputProps}
          >
            <Type
              version='b1'
            >
              {object.response.age} year{object.response.age === 1 ? '' : 's'}
            </Type>
          </Input>
        )}

        {object?.response?.gender !== undefined && (
          <Input
            name='Gender'

            {...inputProps}
          >
            <Type
              version='b1'
            >
              {cleanValue(object.response.gender, { capitalize: true })}
            </Type>
          </Input>
        )}

        {object?.response?.blood_pressure !== undefined && (
          <Input
            name='Blood pressure'

            {...inputProps}
          >
            <Type
              version='b1'
            >
              {object.response.blood_pressure}
            </Type>
          </Input>
        )}

        {object?.response?.body_fat !== undefined && (
          <Input
            name='Body fat'

            {...inputProps}
          >
            <Type
              version='b1'
            >
              {object.response.body_fat}
            </Type>
          </Input>
        )}

        {object?.response?.circumference !== undefined && (
          <Input
            name='Circumference'

            {...inputProps}
          >
            <Type
              version='b1'
            >
              {object.response.circumference}
            </Type>
          </Input>
        )}

        {object?.response?.skinfolds !== undefined && (
          <Input
            name='Skinfolds'

            {...inputProps}
          >
            <Type
              version='b1'
            >
              {object.response.skinfolds}
            </Type>
          </Input>
        )}

        {object?.note !== undefined && (
          <Input
            name='Note'

            {...inputProps}
          >
            <Type
              version='b1'

              dangerouslySetInnerHTML={{
                __html: textToInnerHTML(object.note)
              }}
            />
          </Input>
        )}
      </Inputs>
    )
  };

  const nameCustomer = textToInnerHTML(customer?.name);

  return (
    <ModalForm
      {...props}

      name={`Assessment for ${nameCustomer}`}

      object={object}

      add

      onSubmit={onSubmit}

      onClose={onClose}
    >
      {modal.read}
    </ModalForm>
  );
});

export default Element;
