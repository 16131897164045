import { Service } from '@onesy/api';

import { BaseService } from './base';

class ServiceService extends BaseService<Service> {

  get paginationLimit() {
    return 'all';
  }

  public constructor() {
    super('services');
  }

}

export default new ServiceService();
