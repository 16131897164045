import { Note } from '@onesy/api';

import { BaseService } from './base';

class NoteService extends BaseService<Note> {

  get paginationLimit() {
    return 50;
  }

  public constructor() {
    super('notes');
  }

}

export default new NoteService();
