import { Category } from '@onesy/api';

import { BaseService } from './base';

class CategoryService extends BaseService<Category> {

  get paginationLimit() {
    return 20;
  }

  public constructor() {
    super('categories');
  }

}

export default new CategoryService();
