import { Task } from '@onesy/api';
import OnesySubscription from '@onesy/subscription';

import { BaseService } from './base';

class TaskService extends BaseService<Task> {
  public task = new OnesySubscription<Task>();

  public get paginationLimit() { return 20; }

  public constructor() {
    super('tasks');
  }

}

export default new TaskService();
