import React from 'react';

import { textToInnerHTML } from '@onesy/utils';
import { Line, Type } from '@onesy/ui-react';
import { classNames, style } from '@onesy/style-react';
import { IBaseElement } from '@onesy/ui-react/types';

import { getMediaUrl } from 'utils';

const useStyle = style(theme => ({
  root: {
    width: 'clamp(0px, 670px, 100%)',
    padding: 24,
    borderRadius: 16,
    backgroundColor: theme.palette.background.default[theme.palette.light ? 'primary' : 'quaternary'],
    boxShadow: theme.shadows.values.default[1]
  },

  title: {
    fontFamily: 'arial, sans-serif',
    fontSize: 20,
    fontWeight: 400,
    color: theme.palette.light ? '#1a0dab' : '#8ab4f8'
  },

  description: {
    fontFamily: 'arial, sans-serif',
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.light ? '#4d5156' : '#bdc1c6'
  },

  image: {
    width: 90,
    height: 92,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    borderRadius: 8,
    flex: '0 0 auto'
  },

  rootDate: {
    color: theme.palette.light ? '#70757a' : '#9aa0a6'
  }
}), { name: 'onesy-SEO' });

export interface ISEO extends IBaseElement {
  name?: string;

  description?: string;

  url?: string;

  image?: any;
}

const Element: React.FC<ISEO> = React.forwardRef((props, ref: any) => {
  const {
    name,

    description: description_,

    url,

    image,

    className,

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  const description = textToInnerHTML(description_);

  const valueDescription = (description || 'Description example').slice(0, 160);

  const overflow = description.length > 160;

  return (
    <Line
      gap={1}

      align='center'

      fullWidth
    >
      <Line
        ref={ref}

        gap={4.7}

        direction={{
          default: 'row',
          700: 'column'
        }}

        align='flex-start'

        justify='space-between'

        fullWidth

        className={classNames([
          className,
          classes.root
        ])}

        {...other}
      >
        <Line
          gap={0.5}
        >
          <Type
            className={classes.title}

            dangerouslySetInnerHTML={{
              __html: textToInnerHTML(name) || 'Title example'
            }}
          />

          <Type
            className={classes.description}
          >
            <span className={classes.rootDate}>14.04.2024. — </span>

            <span
              dangerouslySetInnerHTML={{
                __html: `${valueDescription}${overflow ? '...' : ''}`
              }}
            />
          </Type>
        </Line>

        <Line
          style={{
            ...(image && {
              backgroundImage: `url(${getMediaUrl(image, 480)})`
            })
          }}

          className={classes.image}
        />
      </Line>

      <Type
        version='b2'

        weight={100}

        align='center'
      >
        Preview: Google SEO
      </Type>
    </Line>
  );
});

export default Element;
