import { Booking } from '@onesy/api';

import { BaseService } from './base';

class BookingService extends BaseService<Booking> {

  get paginationLimit() {
    return 50;
  }

  public constructor() {
    super('bookings');
  }

}

export default new BookingService();
