import React from 'react';

import { Line } from '@onesy/ui-react';
import { classNames, style } from '@onesy/style-react';

const useStyle = style(theme => ({
  root: {
    overflowX: 'auto',
    flex: '0 0 auto',

    '& > *': {
      flex: '0 0 auto'
    }
  }
}), { name: 'onesy-Analytics' });

const Analytics = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  return (
    <Line
      ref={ref}

      gap={1}

      direction='row'

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      {children}
    </Line>
  );
});

export default Analytics;
