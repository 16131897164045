import { Ingredient } from '@onesy/api';

import { BaseService } from './base';

class IngredientService extends BaseService<Ingredient> {

  get paginationLimit() {
    return 30;
  }

  public constructor() {
    super('ingredients');
  }

}

export default new IngredientService();
