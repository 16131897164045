import { hash, is } from '@onesy/utils';
import OnesySubscription from '@onesy/subscription';
import { IAddMany, IAdditional, IRemoveMany, IResponse, IUpdateMany } from '@onesy/sdk/other';
import { Query } from '@onesy/models';

import MainService from './main';
import { AuthService } from '.';
import { PAGINATION_LIMIT, getErrorMessage } from 'utils';
import { IQuerySubscription, ISelectedSubscription } from 'types';

export interface IQueryObjectsBody {
  id?: string;
  query?: Partial<Query> | undefined;
  options?: IAdditional | undefined;
}

export class BaseService<Model = undefined> {
  public queryObjects = new OnesySubscription<IQuerySubscription>();
  public selectObjects = new OnesySubscription<ISelectedSubscription>();
  public search = new OnesySubscription({});

  public get paginationLimit(): number | string { return PAGINATION_LIMIT; }

  public get sort(): any { return { added_at: -1 }; }

  constructor(
    public route?: string
  ) {
    this.queryObjectsInit(this.queryObjects);

    this.selectedObjectsInit(this.selectObjects);
  }

  public refetch(...args: any) {
    return this.queryObjects.value!.refetch(...args);
  }

  public onQueryAfter = async (result: IResponse) => {
    // any 
  };

  public queryObjectsInit = (subscription: OnesySubscription, method = 'queryPost') => {
    const value = subscription.value || ({} as IQuerySubscription);

    const self = this;

    if (!value.hasOwnProperty('update')) Object.defineProperty(value, 'update', {
      value: function () {
        // emit
        subscription.emit(this);
      }
    });

    if (!value.hasOwnProperty('reset')) Object.defineProperty(value, 'reset', {
      value: function (resetLoaded = true) {
        this.response = [];
        this.pagination = {};
        this.meta = {};

        if (resetLoaded) this.loaded = false;

        this.error = '';

        this.previousBody = {};
        this.previousQuery = {};

        // update
        this.update();
      }
    });

    if (!value.hasOwnProperty('init')) Object.defineProperty(value, 'init', {
      value: function (value_: any) {
        if (is('object', value_)) {
          Object.keys(value_).forEach((item: any) => this[item] = value_[item]);
        }

        // update
        this.update();
      }
    });

    if (!value.hasOwnProperty('refetch')) Object.defineProperty(value, 'refetch', {
      value: function (clear = false) {
        const queryRefetch = { ...this?.previousQuery };

        if (clear) {
          const toRemove = ['next', 'previous', 'skip'];

          toRemove.forEach((item: string) => delete queryRefetch[item]);
        }

        return this.query({
          ...this.previousBody,

          clear,

          query: queryRefetch
        });
      }
    });

    if (!value.hasOwnProperty('updateObject')) Object.defineProperty(value, 'updateObject', {
      value: function (id: string, value: any, replace = false) {
        if (is('array', this.response)) {
          const index = this.response.findIndex((item: any) => item?.id === id);

          if (index > -1) this.response[index] = replace ? value : {
            ...this.response[index],
            ...value
          };
        }

        // update
        this.update();
      }
    });

    if (!value.hasOwnProperty('query')) Object.defineProperty(value, 'query', {
      value: async function (body: any = {}) {
        const {
          id,
          options,
          clear,
          args: argsValue
        } = body;

        let reset = clear;

        this.previousBody = body;

        this.previousQuery = { ...body?.query };

        let loadMore = this.previousQuery.loadMore;

        // clear
        delete this.previousQuery.loadMore;

        const hashPrevious = hash(subscription.value?.query?.query);
        const hashNew = hash(body?.query?.query);

        const isStripe = ['queryInvoicePost', 'queryPaymentMethodPost'].includes(method);

        if (!isStripe) {
          if (subscription.value?.query?.query === undefined || hashPrevious !== hashNew) this.previousQuery.total = true;
        }

        // limit
        if (this.previousQuery.limit === undefined) this.previousQuery.limit = self.paginationLimit;

        // sort
        if (this.previousQuery.sort === undefined || !Object.keys(this.previousQuery.sort || {}).length) this.previousQuery.sort = self.sort;

        const args = [id, this.previousQuery, options].filter(Boolean);

        const result = await MainService.sdk[self.route as 'websites']?.[method as 'queryPost'](...(argsValue !== undefined ? argsValue : args) as any);

        const previous = this;

        if (isStripe) {
          if (!(body?.query?.starting_after || body?.query?.starting_before)) reset = true;

          const {
            data,

            ...paginationStripe
          } = result?.response?.response;

          this.pagination = { ...paginationStripe };

          this.response = [
            ...(reset ? [] : previous?.response || []),
            ...(data || [])
          ];
        }
        else {
          this.pagination = {
            ...previous?.pagination,
            ...result?.response?.pagination
          };

          this.response = !loadMore ? result.response?.response : [
            ...previous?.response,
            ...result?.response?.response
          ];
        }

        this.meta = {
          ...previous?.meta,
          ...result?.response?.meta
        };

        this.length = this.response?.length || 0;

        this.status = result.status;

        this.error = getErrorMessage(result);

        this.loaded = true;

        this.inited = true;

        // on query after 
        await self.onQueryAfter(result);

        // update
        this.update();

        return result;
      }
    });

    // initial value
    subscription.value = value;

    return value;
  }

  public selectedObjectsInit = (subscription: OnesySubscription<ISelectedSubscription>) => {
    const value = subscription.value || ({} as ISelectedSubscription);

    if (!value.selected) value.selected = {};

    if (!value.hasOwnProperty('update')) Object.defineProperty(value, 'update', {
      value: function () {
        // emit
        subscription.emit(this);
      }
    });

    if (!value.hasOwnProperty('reset')) Object.defineProperty(value, 'reset', {
      value: function () {
        this.all = false;
        this.selected = {};

        this.update();
      }
    });

    if (!value.hasOwnProperty('ids')) Object.defineProperty(value, 'ids', {
      get() {
        return Object.keys(this.selected || {});
      }
    });

    if (!value.hasOwnProperty('objects')) Object.defineProperty(value, 'objects', {
      get() {
        return Object.keys(this.selected || {}).map((item: any) => this.selected[item]);
      }
    });

    if (!value.hasOwnProperty('length')) Object.defineProperty(value, 'length', {
      get() {
        return this.ids.length;
      }
    });

    if (!value.hasOwnProperty('isSelected')) Object.defineProperty(value, 'isSelected', {
      value: function (item: any) {
        const selected = this.selected?.[item?.id || item];

        return !!(this.all || selected);
      }
    });

    if (!value.hasOwnProperty('selectAll')) Object.defineProperty(value, 'selectAll', {
      value: function () {
        this.all = true;

        this.update();
      }
    });

    if (!value.hasOwnProperty('unselectAll')) Object.defineProperty(value, 'unselectAll', {
      value: function () {
        this.reset();
      }
    });

    if (!value.hasOwnProperty('select')) Object.defineProperty(value, 'select', {
      value: function (item: any, unselect = true) {
        const selected = this.isSelected(item);

        if (!selected) {
          this.selected[item?.id || item] = item;

          this.update();
        }
        else if (unselect) this.unselect(item);
      }
    });

    if (!value.hasOwnProperty('unselect')) Object.defineProperty(value, 'unselect', {
      value: function (item: any) {
        const selected = this.isSelected(item);

        if (selected) {
          delete this.selected[item?.id || item];

          this.all = false;

          this.update();
        }
      }
    });

    if (!value.hasOwnProperty('selectMany')) Object.defineProperty(value, 'selectMany', {
      value: function (values: any, unselect = true) {
        if (is('array', values) && !!values.length) {
          values.forEach((item: any) => {
            const selected = this.isSelected(item);

            if (!selected) this.selected[item?.id || item] = item;
            else if (unselect) {
              delete this.selected[item?.id || item];

              this.all = false;
            }
          });

          this.update();
        }
      }
    });

    if (!value.hasOwnProperty('unselectMany')) Object.defineProperty(value, 'unselectMany', {
      value: function (values: any) {
        if (is('array', values) && !!values.length) {
          values.forEach((item: any) => {
            const selected = this.isSelected(item);

            if (selected) {
              delete this.selected[item?.id || item];

              this.all = false;
            }
          });

          this.update();
        }
      }
    });

    if (!value.hasOwnProperty('updateMany')) Object.defineProperty(value, 'updateMany', {
      value: function (values: any) {
        if (is('array', values) && !!values.length) {
          values.forEach((item: any) => {
            const selected = this.isSelected(item);

            if (selected) {
              this.selected[item?.id || item] = item;
            }
          });

          this.update();
        }
      }
    });

    // initial value
    subscription.value = value;

    return value;
  }

  public async add(body: any, options?: IAdditional) {
    const response = await MainService.sdk[this.route as 'websites']?.add(body as any, options);

    AuthService.me();

    return response;
  }

  public async addMany(body: IAddMany<any>, options?: IAdditional) {
    const response = await MainService.sdk[this.route as 'websites']?.addMany(body as any, options);

    AuthService.me();

    return response;
  }

  public async query(options?: IAdditional) {
    return MainService.sdk[this.route as 'websites']?.query(options);
  }

  public async queryPost(body?: Partial<Query>, options?: IAdditional) {
    return MainService.sdk[this.route as 'websites']?.queryPost(body as any, options);
  }

  public async queryPublic(options?: IAdditional) {
    return MainService.sdk[this.route as 'websites']?.queryPublic(options);
  }

  public async queryPostPublic(body?: Partial<Query>, options?: IAdditional) {
    return MainService.sdk[this.route as 'websites']?.queryPostPublic(body as any, options);
  }

  public async queryWebsitePublic(options?: IAdditional) {
    return MainService.sdk[this.route as 'websites']?.queryWebsitePublic(options);
  }

  public async queryPostWebsitePublic(body?: Partial<Query>, options?: IAdditional) {
    return MainService.sdk[this.route as 'websites']?.queryPostWebsitePublic(body as any, options);
  }

  public async get(id: string, options?: IAdditional) {
    return MainService.sdk[this.route as 'websites']?.get(id, options);
  }

  public async getWebsitePublic(id: string, options?: IAdditional) {
    return MainService.sdk[this.route as 'websites']?.getWebsitePublic(id, options);
  }

  public async overview(options?: IAdditional) {
    return MainService.sdk[this.route as 'websites']?.overview(options);
  }

  public async overviewPost(body?: any, options?: IAdditional) {
    return MainService.sdk[this.route as 'websites']?.overviewPost(body as any, options);
  }

  public async update(id: string, body_: any, options?: IAdditional) {
    const body = { ...body_ };

    delete body.update;

    return MainService.sdk[this.route as 'websites']?.update(id, body as any, options);
  }

  public async updateMany(body: IUpdateMany<any>, options?: IAdditional) {
    return MainService.sdk[this.route as 'websites']?.updateMany(body as any, options);
  }

  public async remove(id: string, options?: IAdditional) {
    const response = await MainService.sdk[this.route as 'websites']?.remove(id, options);

    AuthService.me();

    return response;
  }

  public async removeMany(body: IRemoveMany<Model>, options?: IAdditional) {
    const response = await MainService.sdk[this.route as 'websites']?.removeMany(body as any, options);

    AuthService.me();

    return response;
  }

}
