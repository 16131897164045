import { Analytic } from '@onesy/api';
import OnesySubscription from '@onesy/subscription';

import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'types';

class AnalyticService extends BaseService<Analytic> {
  public queryWebsiteAnalytics = new OnesySubscription<IQuerySubscription>();
  public selectedWebsiteAnalytics = new OnesySubscription<ISelectedSubscription>();

  public queryURLShortenerAnalytics = new OnesySubscription<IQuerySubscription>();
  public selectedURLShortenerAnalytics = new OnesySubscription<ISelectedSubscription>();

  public queryFormPollAnalytics = new OnesySubscription<IQuerySubscription>();
  public selectedFormPollAnalytics = new OnesySubscription<ISelectedSubscription>();

  public queryOverviewAccountingAnalytics = new OnesySubscription<IQuerySubscription>();

  public constructor() {
    super('analytics');

    this.queryObjectsInit(this.queryWebsiteAnalytics);
    this.selectedObjectsInit(this.selectedWebsiteAnalytics);

    this.queryObjectsInit(this.queryURLShortenerAnalytics);
    this.selectedObjectsInit(this.selectedURLShortenerAnalytics);

    this.queryObjectsInit(this.queryFormPollAnalytics);
    this.selectedObjectsInit(this.selectedFormPollAnalytics);

    this.queryObjectsInit(this.queryOverviewAccountingAnalytics);
  }

}

export default new AnalyticService();
